import React, { useEffect, useRef, useState } from "react";
import { Grid, Box, Typography, Card, CardContent, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PressImage from "../../assets/images/sample-press-release.png";
import PressImageTwo from "../../assets/images/sample-press-release2.webp";
import PressImageThree from "../../assets/images/sample-press-release3.webp";

const BlogComponent = (props) => {

    const { breakpoints } = props;

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (isInView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    const [isReadMoreOne, setReadMoreOne] = useState(false);
    const [isReadMoreTwo, setReadMoreTwo] = useState(false);
    const [isReadMoreThree, setReadMoreThree] = useState(false);

    const handleReadMore = (e, index) => {
        if (index == 0) {
            setReadMoreOne(true);

        } else if (index == 1) {
            setReadMoreTwo(true);

        } else if (index == 2) {
            setReadMoreThree(true);
        }
    }

    const handleHideReadMore = (e, index) => {
        if (index == 0) {
            setReadMoreOne(false);

        } else if (index == 1) {
            setReadMoreTwo(false);

        } else if (index == 2) {
            setReadMoreThree(false);
        }
    }


    return (
        <div ref={sectionRef} style={{ paddingBottom: 60 }}>
            <div class="container-fluid container-xxxl">
                <div class="row">
                    <div class="col-12 px-md-5">
                        <div class="column-card">
                            <div class="row pt-5">
                                <div class="col-7 text-left ">
                                    <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}>Blog&nbsp; ___</Typography><br></br>
                                    <Typography variant="body1" sx={{ color: "#070031", padding: "12px", fontSize: "20px" }}>We build software solutions that solve our clients' business challenges</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Grid container direction="row" sx={{ width: "95%", margin: "auto", mt: "10px" }}>

                    <Grid item xs={12} sx={{ mb: 3 }}>
                        <Card sx={{ borderRadius: 0, "&:hover": { borderTop: "6px solid #99CC00", boxShadow: "rgb(100 100 111 / 22%) 0px 7px 29px 0px" } }}>
                            <CardContent>
                                <Grid container direction="row">
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <img alt="" src={PressImage} style={{ width: "100%", padding: (breakpoints == "xs" || breakpoints == "sm") ? "0px" : "40px", border: "1px solid #fff" }} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{ textAlign: "start", padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : "41px 40px 0px 0px", paddingTop: (breakpoints == "xs" || breakpoints == "sm") ? "10px" : {} }}>
                                        <Grid container sx={{ display: "flex" }}>
                                            <Box>
                                                <Grid>
                                                    <Typography sx={{ color: "#6A6B6C", fontSize: "16px" }}>
                                                        21 Feb 2024
                                                    </Typography>
                                                </Grid>

                                                <Grid sx={{ mt: "12px", mb: (breakpoints == "xs" || breakpoints == "sm") ? "5px" : "20px" }}>
                                                    <Typography sx={{ color: "#070031", fontWeight: "bold", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "18px" : "20px" }}>
                                                        Nextix AND THALES SIGN ATM FOR SINGAPORE
                                                    </Typography>
                                                </Grid>
                                            </Box>

                                            <Grid sx={{ ml: "auto", mt: "-27px", mr: "-10px" }}>
                                                {(!isReadMoreOne) &&
                                                    <IconButton onClick={(e) => handleReadMore(e, 0)}>
                                                        <ExpandMoreIcon sx={{ color: "#99CC00", width: (breakpoints == "xs" || breakpoints == "sm") ? "40px" : "50px", height: (breakpoints == "xs" || breakpoints == "sm") ? "40px" : "50px" }} />
                                                    </IconButton>
                                                }

                                                {(isReadMoreOne) &&
                                                    <IconButton onClick={(e) => handleHideReadMore(e, 0)}>
                                                        <ExpandLessIcon sx={{ color: "#99CC00", width: (breakpoints == "xs" || breakpoints == "sm") ? "40px" : "50px", height: (breakpoints == "xs" || breakpoints == "sm") ? "40px" : "50px" }} />
                                                    </IconButton>
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid sx={{}}>
                                            <Typography sx={{ color: "#070031", fontSize: "16px", padding: "0px 10px 0px 0px", textAlign: "justify" }}>
                                                NextIX and Thales announced today at Singapore Airshow 2024 the signature of a
                                                Memorandum of Understanding (MoU) to support the long-term innovation and
                                                modernization of Singapore’s Air Traffic Management (ATM) infrastructure, the signature
                                                of a Memorandum of Understanding (MoU) to support the long-term innovation and
                                                modernization of Singapore’s Air Traffic Management (ATM) infrastructure the signature.
                                                NextIX and Thales announced today at Singapore Airshow 2024 the signature of a
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {(isReadMoreOne) &&
                                        <Grid sx={{ mt: "-35px", padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : "0px 40px 10px 40px", textAlign: "justify" }}>
                                            <Typography sx={{ color: "#070031", fontSize: "16px" }}>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sx={{ mb: 3 }}>
                        <Card sx={{ borderRadius: 0, "&:hover": { borderTop: "6px solid #99CC00", boxShadow: "rgb(100 100 111 / 22%) 0px 7px 29px 0px" } }}>
                            <CardContent>
                                <Grid container direction="row">
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <img alt="" src={PressImageThree} style={{ width: "100%", padding: (breakpoints == "xs" || breakpoints == "sm") ? "0px" : "40px", border: "1px solid #fff" }} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{ textAlign: "start", padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : "41px 40px 0px 0px", paddingTop: (breakpoints == "xs" || breakpoints == "sm") ? "10px" : {} }}>
                                        <Grid container sx={{ display: "flex" }}>
                                            <Box>
                                                <Grid>
                                                    <Typography sx={{ color: "#6A6B6C", fontSize: "16px" }}>
                                                        21 Feb 2024
                                                    </Typography>
                                                </Grid>

                                                <Grid sx={{ mt: "12px", mb: (breakpoints == "xs" || breakpoints == "sm") ? "5px" : "20px" }}>
                                                    <Typography sx={{ color: "#070031", fontWeight: "bold", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "18px" : "20px" }}>
                                                        NETWORK SECURITY: ANSSI RENEWS QUALIFICATION OF NextIX
                                                    </Typography>
                                                </Grid>
                                            </Box>

                                            <Grid sx={{ ml: "auto", mt: "-27px", mr: "-10px" }}>
                                                {(!isReadMoreTwo) &&
                                                    <IconButton onClick={(e) => handleReadMore(e, 1)}>
                                                        <ExpandMoreIcon sx={{ color: "#99CC00", width: (breakpoints == "xs" || breakpoints == "sm") ? "40px" : "50px", height: (breakpoints == "xs" || breakpoints == "sm") ? "40px" : "50px" }} />
                                                    </IconButton>
                                                }

                                                {(isReadMoreTwo) &&
                                                    <IconButton onClick={(e) => handleHideReadMore(e, 1)}>
                                                        <ExpandLessIcon sx={{ color: "#99CC00", width: (breakpoints == "xs" || breakpoints == "sm") ? "40px" : "50px", height: (breakpoints == "xs" || breakpoints == "sm") ? "40px" : "50px" }} />
                                                    </IconButton>
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid sx={{}}>
                                            <Typography sx={{ color: "#070031", fontSize: "16px", padding: "0px 10px 0px 0px", textAlign: "justify" }}>
                                                NextIX and Thales announced today at Singapore Airshow 2024 the signature of a
                                                Memorandum of Understanding (MoU) to support the long-term innovation and
                                                modernization of Singapore’s Air Traffic Management (ATM) infrastructure, the signature
                                                of a Memorandum of Understanding (MoU) to support the long-term innovation and
                                                modernization of Singapore’s Air Traffic Management (ATM) infrastructure the signature.
                                                NextIX and Thales announced today at Singapore Airshow 2024 the signature of a
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {(isReadMoreTwo) &&
                                        <Grid sx={{ mt: "-35px", padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : "0px 40px 10px 40px", textAlign: "justify" }}>
                                            <Typography sx={{ color: "#070031", fontSize: "16px" }}>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sx={{ mb: "12px" }}>
                        <Card sx={{ borderRadius: 0, "&:hover": { borderTop: "6px solid #99CC00", boxShadow: "rgb(100 100 111 / 22%) 0px 7px 29px 0px" } }}>
                            <CardContent>
                                <Grid container direction="row">
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <img alt="" src={PressImageTwo} style={{ width: "100%", padding: (breakpoints == "xs" || breakpoints == "sm") ? "0px" : "40px", border: "1px solid #fff" }} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{ textAlign: "start", padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : "41px 40px 0px 0px", paddingTop: (breakpoints == "xs" || breakpoints == "sm") ? "10px" : {} }}>
                                        <Grid container sx={{ display: "flex" }}>
                                            <Box>
                                                <Grid>
                                                    <Typography sx={{ color: "#6A6B6C", fontSize: "16px" }}>
                                                        21 Feb 2024
                                                    </Typography>
                                                </Grid>

                                                <Grid sx={{ mt: "12px", mb: (breakpoints == "xs" || breakpoints == "sm") ? "5px" : "20px" }}>
                                                    <Typography sx={{ color: "#070031", fontWeight: "bold", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "18px" : "20px" }}>
                                                        Nextix CANADA NAMED A TOP EMPLOYER FOR YOUNG PEOPLE
                                                    </Typography>
                                                </Grid>
                                            </Box>

                                            <Grid sx={{ ml: "auto", mt: "-27px", mr: "-10px" }}>
                                                {(!isReadMoreThree) &&
                                                    <IconButton onClick={(e) => handleReadMore(e, 2)}>
                                                        <ExpandMoreIcon sx={{ color: "#99CC00", width: (breakpoints == "xs" || breakpoints == "sm") ? "40px" : "50px", height: (breakpoints == "xs" || breakpoints == "sm") ? "40px" : "50px" }} />
                                                    </IconButton>
                                                }

                                                {(isReadMoreThree) &&
                                                    <IconButton onClick={(e) => handleHideReadMore(e, 2)}>
                                                        <ExpandLessIcon sx={{ color: "#99CC00", width: (breakpoints == "xs" || breakpoints == "sm") ? "40px" : "50px", height: (breakpoints == "xs" || breakpoints == "sm") ? "40px" : "50px" }} />
                                                    </IconButton>
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid sx={{}}>
                                            <Typography sx={{ color: "#070031", fontSize: "16px", padding: "0px 10px 0px 0px", textAlign: "justify" }}>
                                                NextIX and Thales announced today at Singapore Airshow 2024 the signature of a
                                                Memorandum of Understanding (MoU) to support the long-term innovation and
                                                modernization of Singapore’s Air Traffic Management (ATM) infrastructure, the signature
                                                of a Memorandum of Understanding (MoU) to support the long-term innovation and
                                                modernization of Singapore’s Air Traffic Management (ATM) infrastructure the signature.
                                                NextIX and Thales announced today at Singapore Airshow 2024 the signature of a
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {(isReadMoreThree) &&
                                        <Grid sx={{ mt: "-35px", padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : "0px 40px 10px 40px", textAlign: "justify" }}>
                                            <Typography sx={{ color: "#070031", fontSize: "16px" }}>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                malesuada lacus ex, sit amet blandit leo lobortis segor.
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default BlogComponent