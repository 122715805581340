import React, { useEffect, useRef, useState } from "react";
import { Grid, TextField, Box, Typography, Checkbox, FormControlLabel, Button } from "@mui/material";
import ReactSelect from "react-select";
import EastIcon from "@mui/icons-material/East";
import AddressIcon from "../../assets/icons/pin-white.png";
import PhoneIcon from "../../assets/icons/phone-white.png";
import EmailIcon from "../../assets/icons/email-white.png";

const ProjectFormComponent = (props) => {

    const { breakpoints } = props;

    const careersOptions = [
        { value: "Web Development", label: "Web Development" },
        { value: "Technical Support", label: "Technical Support" },
        { value: "QA and Testing", label: "QA and Testing" },
        { value: "Administrator", label: "Administrator" },
        { value: "Project Manager", label: "Project Manager" },
    ]

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 99999,
        }),
        control: (styles) => ({
            ...styles,
            backgroundColor: "#fff",
            minHeight: "20px",
            width: 170,
            fontSize: 14,
        })
    }

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (inView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);


    return (
        <div ref={sectionRef} className="container-fluid container-xxxl">
            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  > Tell us your project&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Box sx={{ height: 'auto', padding: 16, paddingTop: 3, paddingBottom: 0 }}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ backgroundColor: "#070031", padding: 4, paddingTop: 4, paddingBottom: 4}}>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{ backgroundColor: "#fff", padding: "30px" }}>
                        <Grid sx={{ textAlign: "start" }}>
                            <Typography sx={{ color: "#070031", fontSize: "16px" }}>
                                Shoot us a message and we'll do our best to make your project happen
                            </Typography>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextField
                                    placeholder="FullName"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ style: { color: "#070031" } }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                borderColor: "#070031",
                                                borderRadius: "0px",
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#070031",
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#070031",
                                            },
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextField
                                    placeholder="Email"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ style: { color: "#070031" } }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                borderColor: "#070031",
                                                borderRadius: "0px",
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#070031",
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#070031",
                                            },
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextField
                                    placeholder="Phone Number"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ style: { color: "#070031" } }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                borderColor: "#070031",
                                                borderRadius: "0px",
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#070031",
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#070031",
                                            },
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ReactSelect
                                    className="basic-single"
                                    isRtl={false}
                                    isClearable={true}
                                    options={careersOptions}
                                    placeholder={<span style={{ color: "rgba(0, 0, 0, 0.54)" }}>Select Field</span>}
                                    styles={{ customStyles }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                            <TextField
                                placeholder="Type your message here*"
                                variant="outlined"
                                fullWidth
                                multiline="true"
                                minRows={5}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#070031",
                                            borderRadius: "0px",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#070031",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#070031",
                                        },
                                    }
                                }}
                            />
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                            <TextField
                                placeholder="No file chosen"
                                variant="outlined"
                                fullWidth
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#070031",
                                            borderRadius: "0px",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#070031",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#070031",
                                        },
                                    }
                                }}
                            />
                        </Grid>
                        <Box sx={{ textAlign: "start", mt: 1 }}>
                            <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label={
                                    <Typography sx={{ fontSize: "16px", color: "#070031" }}>
                                        By submitting this form, I agree to the terms
                                        of <span style={{ color: "#0101FF" }}>Privacy</span> and <span style={{ color: "#0101FF" }}>Terms of Use</span>
                                    </Typography>
                                }
                            />
                        </Box>
                        <Box sx={{ textAlign: "start", mt: 2, mb: 1 }}>
                            <Button variant="contained" endIcon={<EastIcon sx={{ color: "#fff" }} />}
                                sx={{
                                    borderRadius: "18px", backgroundColor: "#99CC00", fontSize: "16px", textTransform: "none", color: "#fff",
                                    "&:hover": { backgroundColor: "#99CC00" }
                                }}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <Grid container justifyContent="center" alignItems="center" sx={{ padding: (breakpoints === "xs" || breakpoints === "sm") ? "20px" : "70px 10px 0px 10px" }}>
                            <Grid container sx={{ display: "block", mb: 8, padding: (breakpoints === "xs" || breakpoints === "sm") ? {} : "0px 10px" }}>
                                <Typography sx={{ color: "#fff", fontSize: (breakpoints === "xs" || breakpoints === "sm") ? "20px" : "24px", fontWeight: "bold", textAlign: "start" }}>
                                    Nextix Cebu Office
                                </Typography>
                                <Grid sx={{ display: "flex", textAlign: "start" }}>
                                    <img alt="" src={AddressIcon} style={{ width: 15, height: 15, marginRight: "10px", marginTop: "3px" }} />
                                    <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                                        5 Ma. Cristina St. Brgy. Capitol Site, Cebu City 6000 Philippines
                                    </Typography>
                                </Grid>
                                <Grid sx={{ display: "flex", textAlign: "start" }}>
                                    <img alt="" src={PhoneIcon} style={{ width: 15, height: 15, marginRight: "10px", marginTop: "3px" }} />
                                    <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                                        +63 (32) 384-9309
                                    </Typography>
                                </Grid>
                                <Grid sx={{ display: "flex", textAlign: "start" }}>
                                    <img alt="" src={EmailIcon} style={{ width: 15, height: 15, marginRight: "10px", marginTop: "4px" }} />
                                    <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                                        info@nextix.co
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ display: "block", padding: (breakpoints === "xs" || breakpoints === "sm") ? {} : "0px 10px" }}>
                                <Typography sx={{ color: "#fff", fontSize: (breakpoints === "xs" || breakpoints === "sm") ? "20px" : "24px", fontWeight: "bold", textAlign: "start" }}>
                                    Nextix Makati Office
                                </Typography>
                                <Grid sx={{ display: "flex", textAlign: "start" }}>
                                    <img alt="" src={AddressIcon} style={{ width: 15, height: 15, marginRight: "10px", marginTop: "3px" }} />
                                    <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                                        3rd Floor Montivar Bldg., 34 Jupiter St., Brgy., Bel-air, Makati City 1200
                                    </Typography>
                                </Grid>
                                <Grid sx={{ display: "flex", textAlign: "start" }}>
                                    <img alt="" src={PhoneIcon} style={{ width: 15, height: 15, marginRight: "10px", marginTop: "3px" }} />
                                    <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                                        +63 (2) 8828-1011, 0915 926 2398
                                    </Typography>
                                </Grid>
                                <Grid sx={{ display: "flex", textAlign: "start" }}>
                                    <img alt="" src={EmailIcon} style={{ width: 15, height: 15, marginRight: "10px", marginTop: "4px" }} />
                                    <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                                        info@nextix.co
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

        </div>
    )
}

export default ProjectFormComponent