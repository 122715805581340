import React, { useEffect, useRef, useState } from "react";
import {Grid, Typography, Card} from "@mui/material";
import AnalyticsIcon from "../../assets/icons/analytics.png";
import MobileAppIcon from "../../assets/icons/mobile-app.png";
import UXIcon from "../../assets/icons/user-experience.png";
import QAIcon from "../../assets/icons/qa-testing.png";
import WebDevIcon from "../../assets/icons/web-dev.png";
import DevOpsIcon from "../../assets/icons/devOps.png";

const HowSoftwareHappenComponent = (props) => {

    const {breakpoints} = props;

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (inView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);


    return (
        <div ref={sectionRef} className="container-fluid container-xxxl">
            <div class="row">
                    <div class="col-12 px-md-5">
                        <div class="column-card">
                            <div class="row">
                                <div class="col-7 text-left ">
                                    <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >How we make the software happen?&nbsp; ___</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ height: 'auto', padding: 4 , paddingTop: 3, paddingBottom: 3 }}>
            <Grid item xs={12} sx={{textAlign: "start", pl: "0px !important"}}>
              
                <Typography variant="h6" sx={{color: "#070031", fontSize: { xs: 28, sm: 34, md: 20 }, pb: "20px", mt: -1}}>
                    We deliver the full cycle software development services to meet accelerating business needs. 
                    Our team covers the whole process of the software development life cycle: from discovery 
                    workshops to successful deployment and post-release support.
                </Typography>
            </Grid>

            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card sx={{borderRadius: "0px !important", padding: (breakpoints == "xs" || breakpoints == "sm") ? "25px" : "25px 25px 25px 40px", 
                        boxShadow: "rgb(100 100 111 / 0%) 0px 7px 29px 0px", 
                        "&:hover": {backgroundColor: "#eaefdb", 
                            boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
                        }}}
                    >
                        <Grid sx={{display: "flex"}}>
                            <img alt="" src={AnalyticsIcon} style={{width: 25, height: 25, marginRight: "12px"}} />

                            <Typography sx={{color: "#070031", fontSize: "18px", fontWeight: "bold"}}>
                                Business Analysis
                            </Typography>
                        </Grid>

                        <Grid sx={{textAlign: "start", mt: "12px"}}>
                            <Typography sx={{color: "#070031", fontSize: "16px"}}>
                                Leverage our BA experience to make the product work as it should.
                                Being a part of pre-development stage, business analysis ensures the
                                software will meet your aims.
                            </Typography>
                        </Grid>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card sx={{borderRadius: "0px !important", padding: (breakpoints == "xs" || breakpoints == "sm") ? "25px" : "25px 25px 25px 40px", 
                        boxShadow: "rgb(100 100 111 / 0%) 0px 7px 29px 0px", 
                        "&:hover": {backgroundColor: "#eaefdb", 
                            boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
                        }}}
                    >
                        <Grid sx={{display: "flex"}}>
                            <img alt="" src={MobileAppIcon} style={{width: 25, height: 25, marginRight: "12px"}} />

                            <Typography sx={{color: "#070031", fontSize: "18px", fontWeight: "bold"}}>
                                Mobile Application Development
                            </Typography>
                        </Grid>

                        <Grid sx={{textAlign: "start", mt: "12px"}}>
                            <Typography sx={{color: "#070031", fontSize: "16px"}}>
                                We uplift your business potential with a smart mobile solution and get
                                big ideas fit on a small screen with native or cross-platform mobile 
                                applications.
                            </Typography>
                        </Grid>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card sx={{borderRadius: "0px !important", padding: (breakpoints == "xs" || breakpoints == "sm") ? "25px" : "25px 25px 25px 40px", 
                        boxShadow: "rgb(100 100 111 / 0%) 0px 7px 29px 0px", 
                        "&:hover": {backgroundColor: "#eaefdb", 
                            boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
                        }}}
                    >
                        <Grid sx={{display: "flex"}}>
                            <img alt="" src={UXIcon} style={{width: 25, height: 25, marginRight: "12px"}} />

                            <Typography sx={{color: "#070031", fontSize: "18px", fontWeight: "bold"}}>
                                UI / UX Design
                            </Typography>
                        </Grid>

                        <Grid sx={{textAlign: "start", mt: "12px"}}>
                            <Typography sx={{color: "#070031", fontSize: "16px"}}>
                                Whether it is a basic website or complex enterprise software, our
                                designers know how to provide relevant and meaningful interfaces
                                guided by a human-centric approach.
                            </Typography>
                        </Grid>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card sx={{borderRadius: "0px !important", padding: (breakpoints == "xs" || breakpoints == "sm") ? "25px" : "25px 25px 25px 40px", 
                        boxShadow: "rgb(100 100 111 / 0%) 0px 7px 29px 0px", 
                        "&:hover": {backgroundColor: "#eaefdb", 
                            boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
                        }}}
                    >
                        <Grid sx={{display: "flex"}}>
                            <img alt="" src={QAIcon} style={{width: 25, height: 25, marginRight: "12px"}} />

                            <Typography sx={{color: "#070031", fontSize: "18px", fontWeight: "bold"}}>
                                QA and Testing
                            </Typography>
                        </Grid>

                        <Grid sx={{textAlign: "start", mt: "12px"}}>
                            <Typography sx={{color: "#070031", fontSize: "16px"}}>
                                Make your solution well-adjusted with our in-house QA team to provide
                                your business with the highest value.
                            </Typography>
                        </Grid>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card sx={{borderRadius: "0px !important", padding: (breakpoints == "xs" || breakpoints == "sm") ? "25px" : "25px 25px 25px 40px", 
                        boxShadow: "rgb(100 100 111 / 0%) 0px 7px 29px 0px", 
                        "&:hover": {backgroundColor: "#eaefdb", 
                            boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
                        }}}
                    >
                        <Grid sx={{display: "flex"}}>
                            <img alt="" src={WebDevIcon} style={{width: 25, height: 25, marginRight: "12px"}} />

                            <Typography sx={{color: "#070031", fontSize: "18px", fontWeight: "bold"}}>
                                Web Software Development
                            </Typography>
                        </Grid>

                        <Grid sx={{textAlign: "start", mt: "12px"}}>
                            <Typography sx={{color: "#070031", fontSize: "16px"}}>
                                Our engineers deliver web software solutions for B2B and B2C
                                companies of different complexity: from single-page websites to fullpledged web applications.
                            </Typography>
                        </Grid>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card sx={{borderRadius: "0px !important", padding: (breakpoints == "xs" || breakpoints == "sm") ? "25px" : "25px 25px 25px 40px", 
                        boxShadow: "rgb(100 100 111 / 0%) 0px 7px 29px 0px", 
                        "&:hover": {backgroundColor: "#eaefdb", 
                            boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
                        }}}
                    >
                        <Grid sx={{display: "flex"}}>
                            <img alt="" src={DevOpsIcon} style={{width: 25, height: 25, marginRight: "12px"}} />

                            <Typography sx={{color: "#070031", fontSize: "18px", fontWeight: "bold"}}>
                                DevOps
                            </Typography>
                        </Grid>

                        <Grid sx={{textAlign: "start", mt: "12px"}}>
                            <Typography sx={{color: "#070031", fontSize: "16px"}}>
                                With a team of DevOps under the roof, we ensure high quality of the
                                software and a make the release of your product secure and
                                successful.
                            </Typography>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
        </div>
    )
}

export default HowSoftwareHappenComponent