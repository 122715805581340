import { Box, Link } from "@mui/material"
import LandingComponent from "./landing.component"
import OurPromiseLandingComponent from "./our-promise-landing.component"
import WhatWeDoLandingComponent from "./what-we-do-landing.component"
import MeetOurPeopleLandingComponent from "./meet-our-people-landing.component"
import LatestNewsLandingComponent from "./latest-news-landing.component"
import CapabilitiesByDomainLandingComponent from "./capabilities-by-domain-landing.component"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { useEffect } from "react"

const LandingContentComponent = () => {


    return (

        <Box>
            <LandingComponent />
            <Box sx={{ mb: 10 }} />
            <WhatWeDoLandingComponent />
            <Box sx={{ mb: 10 }} />
            <OurPromiseLandingComponent />
            <Box sx={{ mb: 10 }} />
            <MeetOurPeopleLandingComponent />
            <Box sx={{ mb: 10 }} />
            <CapabilitiesByDomainLandingComponent />
            <Box sx={{ mb: 10 }} />
            <LatestNewsLandingComponent />
            <Box sx={{ mb: 10 }} />

            <Box className="container-fluid container-xxxl">
                <div className="row">
                    <div className="col-12 px-md-5">
                        <div className="column-card">
                            <div className="row">
                                <div class="socialfollow aem-GridColumn aem-GridColumn--default--12" style={{ textAlign: 'start' }} >
                                    <section class="col-12-soc" aria-label="follow us on social media" style={{ display: 'flex', alignItems: 'center', padding: '0px' }} >
                                        <div class="base font-weight-normal d-inline align-middle mr-g">
                                            Follow Nextix
                                        </div> &nbsp;&nbsp;&nbsp;
                                        <div class="soc-widget">
                                            <Link target="_blank" href="https://www.facebook.com/OVCodeSwitzerlandAG/" underline="none">
                                                <i><FontAwesomeIcon icon={faFacebook} /></i>
                                            </Link>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Box>
        </Box>

    )
}

export default LandingContentComponent