import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import awardsImage from '../../assets/images/confident-asian-female-entrepreneur-saleswoman-suit-introduce-product-showing-place-clients-bu.png';

const AwardsAndRecognitions = () => {
    const navigate = useNavigate();
    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (inView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    return (
        <div ref={sectionRef} className="container-fluid container-xxxl">
            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >Awards and Recognitions&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Grid container sx={{ textAlign: 'start', position: 'relative' }} spacing={2}>
                <Grid item xs={12} sm={5.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <img src={awardsImage} alt="Awards and Recognitions" style={{ width: '95%', height: 'auto', objectFit: 'cover' }} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6.5}>
                    <Box sx={{ width: '100%', margin: 'auto' }}>
                        <Typography variant="body1" align="left" sx={{ color: '#070031', textAlign: 'justify', mb: 2, fontSize: { xs: 28, sm: 34, md: 20 } }}>
                            • <b style={{ marginRight: '10px' }}>2017</b>Finalist in Citi Tech for Integrity Challenge. Singapore*
                        </Typography>
                        <Typography variant="body1" align="left" sx={{ color: '#070031', textAlign: 'justify', mb: 2, fontSize: { xs: 28, sm: 34, md: 20 } }}>
                            • <b style={{ marginRight: '10px' }}>2012</b>ON3, Silicon Valley Winner (GroupJump and Miimove). Silicone Valley USA*
                        </Typography>
                        <Typography variant="body1" align="left" sx={{ color: '#070031', textAlign: 'justify', mb: 2, fontSize: { xs: 28, sm: 34, md: 20 } }}>
                            • <b style={{ marginRight: '10px' }}>2011</b>Presidential Award- Eureka Call Center. Singapore*
                        </Typography>
                        <Typography variant="body1" align="left" sx={{ color: '#070031', textAlign: 'justify', mb: 2, fontSize: { xs: 28, sm: 34, md: 20 } }}>
                            • <b style={{ marginRight: '10px' }}>2010</b>2nd Place Most Innovative Call Center, Call Center Association in Singapore-Eureka Call Center. Singapore*
                        </Typography>
                        <Typography variant="body1" align="left" sx={{ color: '#070031', textAlign: 'justify', mb: 2, fontSize: { xs: 28, sm: 34, md: 20 } }}>
                            • <b style={{ marginRight: '10px' }}>2010</b>e-Services Outstanding Client Application for Call Center System. Manila, Philippines
                        </Typography>
                        <Typography variant="body1" align="left" sx={{ color: '#070031', textAlign: 'justify', mb: 2, fontSize: { xs: 28, sm: 34, md: 20 } }}>
                            • <b style={{ marginRight: '10px' }}>2009</b>Digium Pioneer Award Abot Tanaw Global Video Telephony. Arizona, USA*
                        </Typography>
                        <Typography variant="body1" align="left" sx={{ color: '#070031', textAlign: 'justify', mb: 2, fontSize: { xs: 28, sm: 34, md: 20 } }}>
                            • <b style={{ marginRight: '10px' }}>2009</b>Digium ROI Award Taguig Unified Telecommunication System. Arizona, USA*
                        </Typography>
                        <Typography variant="body1" align="left" sx={{ color: '#070031', textAlign: 'justify', mb: 2, fontSize: { xs: 28, sm: 34, md: 20 } }}>
                            • <b style={{ marginRight: '10px' }}>2009</b>e-Services Outstanding Client Application of the Year- Dictation Capturing System (DTI). Manila Philippines.
                        </Typography>
                        <Typography variant="body1" align="right" sx={{ color: '#070031', pr: { xs: 2, sm: 4, md: 6 }, pt: { xs: 1, sm: 2, md: 3 }, textAlign: { xs: 'left', sm: 'left', md: 'right' }, fontSize: { xs: 28, sm: 34, md: 20 } }}>
                            <i>*International Award</i>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default AwardsAndRecognitions;
