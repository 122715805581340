import {Grid, Box, Typography, TextField, Button, Checkbox, FormControlLabel} from "@mui/material";
import JoinUsComponent from "../components/join-us-page/join-us.component";



const JoinUsScreen = (props) => {

    const {breakpoints} = props;

    return (
        
        <Box sx={{backgroundColor: "#F3F3F3"}}>
            <div class="container-fluid container-xxxl">
                <JoinUsComponent breakpoints={breakpoints} />
            </div>
        </Box>

    )
}

export default JoinUsScreen 