import React from "react";
import { Box, Link } from "@mui/material";
import BannerComponent from "../components/contact-us-page/banner.component";
import HowSoftwareHappenComponent from "../components/contact-us-page/how-software-happen.component";
import DiscussProjectComponent from "../components/contact-us-page/discuss-project.component";
import ProjectFormComponent from "../components/contact-us-page/project-form.component";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const ContactUsScreen = (props) => {

    const { breakpoints } = props;

    return (
        <>
            <BannerComponent breakpoints={breakpoints} />

            <Box sx={{ mb: 10 }} />

            <HowSoftwareHappenComponent breakpoints={breakpoints} />
            
            <Box sx={{ mb: 10 }} />

            <DiscussProjectComponent breakpoints={breakpoints} />

            <Box sx={{ mb: 10 }} />

            <ProjectFormComponent breakpoints={breakpoints} />

            <Box sx={{ mb: 10 }} />

            <Box className="container-fluid container-xxxl">
                <div className="row">
                    <div className="col-12 px-md-5">
                        <div className="column-card">
                            <div className="row">
                                <div class="socialfollow aem-GridColumn aem-GridColumn--default--12" style={{ textAlign: 'start' }} >
                                    <section class="col-12-soc" aria-label="follow us on social media" style={{ display: 'flex', alignItems: 'center', padding: '0px' }} >
                                        <div class="base font-weight-normal d-inline align-middle mr-g">
                                            Follow Nextix
                                        </div> &nbsp;&nbsp;&nbsp;
                                        <div class="soc-widget">
                                            <Link target="_blank" href="https://www.facebook.com/OVCodeSwitzerlandAG/" underline="none">
                                                <i><FontAwesomeIcon icon={faFacebook} /></i>
                                            </Link>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Box>
        </>
    )
}

export default ContactUsScreen