import { Box, Button, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const WebAppbarComponent = (props) => {
  const {
    text,
    handleWeAreNextixMenuOpen,
    handleServicesMenuOpen,
    handleSolutionsMenuOpen,
    handleResourcesMenuOpen,
    handleContactUsMenuOpen
  } = props;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        variant="text"
        disableElevation
        onClick={handleWeAreNextixMenuOpen}
        className="appbar-margin"
        sx={{
          marginTop: '.8em', color: '#000',
          flexGrow: 1,
          // ...(selectedWhoWeAre ? { color: '#017eff' } : {})
        }}
        endIcon={<ExpandMoreIcon />}
      >
        <Typography sx={{ fontSize: 18, textTransform: 'none', height: '30px' }}>
          {text.aboutUs}
        </Typography>
      </Button>

      <Button
        variant="text"
        disableElevation
        onClick={handleServicesMenuOpen}
        className="appbar-margin"
        sx={{
          marginTop: '.8em', color: '#000',
          flexGrow: 1,
          // ...(selectedWhatWeDo ? { color: '#017eff' } : {})
        }}
        endIcon={<ExpandMoreIcon />}
      >
        <Typography sx={{ fontSize: 18, textTransform: 'none', height: '30px' }}>
          {text.services}
        </Typography>
      </Button>

      <Button
        variant="text"
        disableElevation
        onClick={handleSolutionsMenuOpen}
        className="appbar-margin"
        sx={{
          marginTop: '.8em', color: '#000',
          flexGrow: 1,
          // ...(selectedNews ? { color: '#017eff' } : {})
        }}
        endIcon={<ExpandMoreIcon />}
      >
        <Typography sx={{ fontSize: 18, textTransform: 'none', height: '30px' }}>
          {text.solutions}
        </Typography>
      </Button>

      <Button
        variant="text"
        disableElevation
        onClick={handleResourcesMenuOpen}
        className="appbar-margin"
        sx={{
          marginTop: '.8em', color: '#000',
          flexGrow: 1,
          // ...(selectedContactUs ? { color: '#017eff' } : {})
        }}
        endIcon={<ExpandMoreIcon />}
      >
        <Typography sx={{ fontSize: 18, textTransform: 'none', height: '30px' }}>
          {text.resources} {/* Ensure this property exists in the text object */}
        </Typography>
      </Button>
      <Button
        variant="text"
        disableElevation
        onClick={handleContactUsMenuOpen} 
        className="appbar-margin"
        sx={{
          marginTop: '.8em', color: '#000',
          flexGrow: 1,
          // ...(selectedContactUs ? { color: '#017eff' } : {})
        }}
        endIcon={<ExpandMoreIcon />}
      >
        <Typography sx={{ fontSize: 18, textTransform: 'none', height: '30px' }}>
          {text.contactUs} {/* Ensure this property exists in the text object */}
        </Typography>
      </Button>
    </Box>
  );
};

export default WebAppbarComponent;
