import { Box, Grid, Typography } from "@mui/material"
import MissionVisionValuesImageComponent from "./mission-vision-values-image.component"
import { useEffect, useRef, useState } from "react";
import {Link} from 'react-router-dom';

const MissionVisionValues = () => {

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (isInView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    return (
        <div ref={sectionRef} >
            <Box sx={{ backgroundColor: '#f2f2f2' }}>
                <div class="container-fluid container-xxxl">
                    <div class="row">
                        <div class="col-12 px-md-5">
                            <div class="column-card">
                                <div class="row pt-5">
                                    <div class="col-7 text-left ">
                                        <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >Vision, Mission, Values&nbsp; ___</Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <MissionVisionValuesImageComponent />

                </div>
            </Box>
        </div>
    )
}

export default MissionVisionValues