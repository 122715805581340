import React from "react";
import {Box} from "@mui/material";
import BannerComponent from "../components/news-and-events-page/banner.component";
import PressReleaseComponent from "../components/news-and-events-page/press-release.component";
import InsideStoriesComponent from "../components/news-and-events-page/inside-stories.component";
import HighlightsComponent from "../components/news-and-events-page/highlights.component";

const NewsEventsComponent = (props) => {

    const {breakpoints} = props;

    return (
       <>
            <BannerComponent breakpoints={breakpoints} />

            <Box sx={{backgroundColor: "#f3f3f3"}}>
                <PressReleaseComponent breakpoints={breakpoints} />
            </Box> 

            <Box sx={{backgroundColor: "#f3f3f3"}}>
                <InsideStoriesComponent breakpoints={breakpoints} />
            </Box> 

            <Box sx={{backgroundColor: "#f3f3f3"}}>
                <HighlightsComponent breakpoints={breakpoints} />
            </Box> 
       </>
    )
}

export default NewsEventsComponent