import { Box, Button, SliderValueLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const translations = {
    english: {
        gameChangersShortTitle: "Nextix Inc.",
        gameChangersLongTitle: "Building Digital Innovations",
        learnMore: "Learn More"
    },
    korean: {
        gameChangersShortTitle: "Nextix Inc.",
        gameChangersLongTitle: "디지털 정보 구축",
        learnMore: "더 알아보세요"
    },
    spanish: {
        gameChangersShortTitle: "Nextix Inc.",
        gameChangersLongTitle: "Creación de innovaciones digitales",
        learnMore: "Aprende Más"
    }
};

const LandingComponent = () => {
    const navigate = useNavigate();
    const [language, setLanguage] = useState("english");

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            setLanguage(storedLanguage);
        }
    }, []);

    return (
        <Box class="container-fluid container-xxxl">
            <Box class="bannerOverlayArea">
            <Box class="bannerOverlayEmbed embed-responsive embed-responsive-16by9">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/stE6bSylt_Y?" title="Siempre es bonito ver el nacimiento de algo." frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </Box>

                <Box class="bannerOverlayText col-12 pt-2 pb-3 pb-md-5 pl-md-5" style={{ textAlign: 'start' }}>
                    <Typography className="bannerTextShortTitle" sx={{ fontSize: { xs: 30, sm: 40, md: 50  } }}>
                        {translations[language].gameChangersShortTitle}
                    </Typography>
                    <Typography className="bannerTextLongTitle d-none d-md-block" sx={{ fontSize: { xs: 28, sm: 34, md: 38  } , mt: 2}}>
                        {translations[language].gameChangersLongTitle}
                    </Typography>
                    <Button class="actionButton mt-md-4" onClick={() => navigate('/who-we-are/we-are-Nextix')} >{translations[language].learnMore}</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default LandingComponent; 

