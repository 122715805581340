import React from "react";
import {Box} from "@mui/material";
import BannerComponent from "../components/careers-page/banner.component";
import WhyJoinUsComponent from "../components/careers-page/why-join-us.component";

const CareersComponent = (props) => {

    const {breakpoints} = props;

    return (
       <>
            <BannerComponent breakpoints={breakpoints} />

            {/* <Box sx={{ mb: 5 }} /> */}

            <Box sx={{backgroundColor: "#f3f3f3"}}>
                <WhyJoinUsComponent breakpoints={breakpoints} />
            </Box> 
       </>
    )
}

export default CareersComponent