import React, { useEffect } from "react";
import {Box} from "@mui/material";
import BannerComponent from "../components/resources/banner.component";
import BlogComponent from "../components/resources/blog.component.jsx";
import WhitepaperComponent from "../components/resources/whitepaper.component.jsx";

const ResourcesScreen = (props) => {

    const {breakpoints} = props;

    useEffect(() => {
        const hash = window.location.hash;
        if (hash === '#whitepapers') {
          const element = document.getElementById('whitepapers');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            window.history.replaceState(null, '', window.location.pathname);
          }
        } 
    
      }, []);

    return (
       <>
            <BannerComponent breakpoints={breakpoints} />

            <Box sx={{ mb: 2 }} />

            <BlogComponent breakpoints={breakpoints} />

            <Box id="whitepapers"></Box>
            <Box sx={{ mb: 5 }} />

            <WhitepaperComponent breakpoints={breakpoints} />
        
       </>
    )
}

export default ResourcesScreen