import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WhatWeDoBanner from '../../assets/images/what_we_do.png';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  // {
  //   label: 'Bird',
  //   imgPath:
  //     'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  // },
  // {
  //   label: 'Bali, Indonesia',
  //   imgPath:
  //     'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  // },
  // {
  //   label: 'Goč, Serbia',
  //   imgPath:
  //     'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  // },
];

function LandingImageComponent() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ position: 'relative' , justifyContent: 'flex-end', textAlign: 'end' }}>
      <img src={WhatWeDoBanner} style={{width: '90%', height: '50%'}} />
    </Box>
    // <Box sx={{ position: 'relative' }}>
    //   <AutoPlaySwipeableViews
    //     axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
    //     index={activeStep}
    //     onChangeIndex={handleStepChange}
    //     enableMouseEvents
    //     interval={5000} // Adjust autoplay interval as per your preference
    //   >
    //     {images.map((step, index) => (
    //       <div key={step.label}>
    //         {Math.abs(activeStep - index) <= 2 ? (
    //          <Box
    //            component="img"
    //            sx={{
    //              height: 500,
    //              display: 'block',
    //              maxWidth: 800,
    //              overflow: 'hidden',
    //              width: '90%',
    //            }}
    //            src={WhatWeDoBanner}
    //            alt={step.label}
    //          />
    //         ) : null}
    //       </div>
    //     ))}
    //   </AutoPlaySwipeableViews>
      // {/* <MobileStepper
      //   steps={maxSteps}
      //   position="static"
      //   activeStep={activeStep}
      //   variant="none"
      //   nextButton={
      //     <Button
      //       size="small"
      //       onClick={handleNext}
      //       disabled={activeStep === maxSteps - 1}
      //       sx={{ borderRadius: '50%',  color: "#fff"  }} // Optional styling
      //     >
      //       <ArrowCircleRightIcon sx={{width: 50, height: 50}} />
      //     </Button>
      //   }
      //   backButton={
      //     <Button
      //       size="small"
      //       onClick={handleBack}
      //       disabled={activeStep === 0}
      //       sx={{ borderRadius: '50%', color: "#fff"  }} // Optional styling
      //     >
      //       <ArrowCircleLeftIcon sx={{width: 50, height: 50}} />

      //     </Button>
      //   }
      //   sx={{
      //     position: 'absolute',
      //     bottom: 0,
      //     right: 0,
      //     backgroundColor: 'transparent'
      //   }}
      // /> */}
    // </Box>
  );
}

export default LandingImageComponent;
