import React from "react";
import useWidth from "../hooks/useWidth";
import WhatWeDoComponent from "../components/what-we-do/what-we-do.component"

const WhatWeDoScreen = () => {

    const breakpoints = useWidth();

    return (
      <div>
        <WhatWeDoComponent breakpoints={breakpoints} />
      </div>
    )
  }

export default WhatWeDoScreen