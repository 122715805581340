import {Grid} from "@mui/material";
import NewsEventsBanner from "../../assets/images/news-events-banner.jpg";

const BannerComponent = (props) => {

    const {breakpoints} = props;

    return (
        <Grid container direction="row" sx={{backgroundColor: "#0700310a", borderTop: "1px solid white", width: "100%"}}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <img alt="" src={NewsEventsBanner} style={{width: "100%", height: "440px", objectFit: "cover"}} />
            </Grid>
        </Grid>
    )
}

export default BannerComponent