import React from "react";
import { Grid, Box, Typography, Card, Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import EastIcon from "@mui/icons-material/East";
import SampleInsideStories from "../../assets/images/sample-inside-stories.webp";
import SampleInsideStories2 from "../../assets/images/sample-inside-stories2.webp";
import SampleInsideStories3 from "../../assets/images/sample-inside-stories3.jpg";

const InsideStoriesComponent = (props) => {
    const { breakpoints } = props;

    const insideStoriesCollection = [
        {
            image: SampleInsideStories,
            title: "SUSTAINING A NATIONAL WONDER WITH AI",
            description: "The Mojave Desert in the southwestern United States is a vast landscape combining mountains and dried lake beds, forests, and wildflower fields. It has a rich cultural history, with 12,000-year-old archaeological sites, and it harbors protected species such as golden eagles and desert tortoises, as well as its famous Joshua trees, some of which are 900 years old and 30 feet tall. But according to Lukas Agnew, senior consultant at Capgemini’s insights and data practice, this unique environment is under threat.",
        },
        {
            image: SampleInsideStories2,
            title: "SUSTAINING A NATIONAL WONDER WITH AI",
            description: "The Mojave Desert in the southwestern United States is a vast landscape combining mountains and dried lake beds, forests, and wildflower fields. It has a rich cultural history, with 12,000-year-old archaeological sites, and it harbors protected species such as golden eagles and desert tortoises, as well as its famous Joshua trees, some of which are 900 years old and 30 feet tall. But according to Lukas Agnew, senior consultant at Capgemini’s insights and data practice, this unique environment is under threat.",
        },
        {
            image: SampleInsideStories3,
            title: "SUSTAINING A NATIONAL WONDER WITH AI",
            description: "The Mojave Desert in the southwestern United States is a vast landscape combining mountains and dried lake beds, forests, and wildflower fields. It has a rich cultural history, with 12,000-year-old archaeological sites, and it harbors protected species such as golden eagles and desert tortoises, as well as its famous Joshua trees, some of which are 900 years old and 30 feet tall. But according to Lukas Agnew, senior consultant at Capgemini’s insights and data practice, this unique environment is under threat.",
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };

    return (
        <Grid container direction="row" sx={{ backgroundColor: "#f3f3f3", width: "91%", margin: "auto", mt: "-10px" }}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography
                    variant="h6"
                    sx={{
                        color: "#070031",
                        fontSize: breakpoints === "xs" || breakpoints === "sm" ? "22px" : "30px",
                        fontWeight: "bold",
                        pt: "16px",
                        pb: "20px",
                    }}
                >
                    Take a closer look at our inside stories
                </Typography>
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>
                <Box sx={{ mb: 4, margin: "auto", overflow: "hidden" }}>
                    <Slider {...settings}>
                        {insideStoriesCollection.map((story, index) => (
                            <Card
                                key={index}
                                sx={{
                                    borderRadius: 0,
                                    boxShadow: "rgb(100 100 111 / 0%) 0px 7px 29px 0px",
                                    overflow: "hidden",
                                }}
                            >
                                <Grid container direction="row">
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <img
                                            alt=""
                                            src={story.image}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <Box
                                            sx={{
                                                margin:
                                                    breakpoints === "xs" || breakpoints === "sm"
                                                        ? "20px 30px 0px 30px"
                                                        : "50px 30px 0px 30px",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    textAlign: "start",
                                                    color: "#070031",
                                                    fontWeight: "bold",
                                                    fontSize: "20px",
                                                }}
                                            >
                                                {story.title}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: "#070031",
                                                    textAlign: "justify",
                                                    padding:
                                                        breakpoints === "xs" || breakpoints === "sm"
                                                            ? "20px 16px 10px 0px"
                                                            : "40px 16px 10px 0px",
                                                    fontSize: "16px",
                                                }}
                                            >
                                                {story.description}
                                            </Typography>
                                            <Box sx={{ textAlign: "end", padding: "10px 11px 30px 0px" }}>
                                                <Button
                                                    variant="text"
                                                    endIcon={<EastIcon sx={{ color: "#99CC00" }} />}
                                                    sx={{
                                                        textTransform: "none",
                                                        color: "#99CC00",
                                                        padding: "6px 8px 8px 0px",
                                                        fontSize: "16px",
                                                    }}
                                                    onClick={() => (window.location.href = "/read-more")}
                                                >
                                                    Read more
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Card>
                        ))}
                    </Slider>
                </Box>
            </Grid>
        </Grid>
    );
};

export default InsideStoriesComponent;
