import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
*/

function useWidth() {

    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    
    return (
        keys.reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));

            return !output && matches ? key : output;

        }, null) || "xs"
    );

}

export default useWidth