import React from 'react';
import imagePath from '../../assets/images/who-we-are/our-promise2.JPG';
import { Box, Typography } from '@mui/material';

const BannerComponent = (props) => {
    const { breakpoints } = props;

    return (
        <Box sx={{ textAlign: 'start' }}>
            <div class="container-fluid container-xxxl">
                <div class="bannerOverlayArea pb-3 pb-md-0">

                    <div className="bannerOverlayMinimizeImage bannerFull"
                        style={{
                            backgroundImage: `url(${imagePath})`,
                            backgroundPosition: '50% 90%',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}>
                    </div>

                    <div class="bannerOverlayText col-12  pt-2 pb-3 pb-md-5 pl-md-5" >
                        <div class="bannerTextShortTitle"></div>

                        <div class="bannerTextSubTitle d-none d-md-block">
                            <Typography className="bannerTextShortTitle" sx={{ fontSize: { xs: 30, sm: 40, md: 50 } }}>Who We Are</Typography>
                        </div>
                    </div>

                </div>
            </div>
        </Box>

    );
};

export default BannerComponent;
