import { Button, Grid, Typography } from "@mui/material";
import WeAreNextixImageComponent from "./we-are-nextix.image-component";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import weAreNextix from '../../assets/images/contact-us/contact-eight.jpg'

const WeAreNextixComponent = () => {
    const navigate = useNavigate();
    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (isInView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    return (
        <div ref={sectionRef} className="container-fluid container-xxxl">
            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >We Are Nextix&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Grid container sx={{ textAlign: 'start', position: 'relative', padding: '20px', paddingTop: 0, paddingBottom: 0 }}>
                <Grid item xs={12} sm={6.5}>
                    <div style={{
                        position: 'inherit',
                        // bottom: '20px',
                        left: 0,
                        width: '100%',
                        textAlign: 'start'
                    }}>
                        <Typography sx={{ textAlign: 'justify', fontSize: { xs: 28, sm: 34, md: 20 }, fontWeight: 'regular', mb: 5, mt: 15, mr: 5 }}>
                            Nextix Inc. is an award-winning innovation company that specializes in universally available information and communication technology solutions for the consumer, SME, enterprise, and government.
                            <br /><br />
                            Nextix constantly aims to drive technology to the next level with robust, scalable & agile systems, creating infinite feature-rich applications at the least prices possible. The company´s expertise creates more agile and multi-faceted technology innovations and solutions. All Nextix technologies leverage the internet to offer communication systems for the internet age.
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={5.5}>
                    <img src={weAreNextix} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </Grid>
            </Grid>
        </div>
    );
}

export default WeAreNextixComponent;
