import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography, Card, CardContent, Box } from "@mui/material";
import SampleHighlight from "../../assets/images/sample-highlight.jpg";
import SampleHighlightTwo from "../../assets/images/sample-highlight2.png";
import SampleHighlightThree from "../../assets/images/sample-highlight3.jpg";
import SampleHighlightFour from "../../assets/images/sample-highlight.jpg";
import SampleHighlightFive from "../../assets/images/sample-highlight2.png";
import SampleHighlightSix from "../../assets/images/sample-highlight3.jpg";

function HighlightsCard(props) {

    const { highlight, breakpoints } = props;

    const [isImageHovered, setImageHovered] = useState(false);

    const handleMouseEnter = () => {
        setImageHovered(true);
    }

    const handleMouseLeave = () => {
        setImageHovered(false);
    }

    return (
        <Card sx={{ borderRadius: 0, height: "400px" }}>
            <CardContent sx={{ textAlign: "start", color: "#070031", padding: "2px" }}>
                <img alt="" src={highlight.image}
                    style={{
                        width: "100%",
                        height: "400px",
                        objectFit: "cover",
                        transition: "transform 2s ease",
                        transform: (isImageHovered) ? "scale(1.2)" : "scale(1)"
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />

                <Typography sx={{ fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "16px" : "18px", position: "absolute", color: "#fff", mt: "-45px", ml: "16px", opacity: "95%" }}>
                    {highlight.description}
                </Typography>
            </CardContent>
        </Card>
    )
}

const CustomSolutionsComponent = (props) => {

    const { breakpoints } = props;

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (isInView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    const highlightsCollection = [
        { image: SampleHighlight, description: "Using AI to protect the Mojave Desert" },
        { image: SampleHighlightTwo, description: "Using AI to protect the Mojave Desert" },
        { image: SampleHighlightThree, description: "Using AI to protect the Mojave Desert" },
        { image: SampleHighlightFour, description: "Using AI to protect the Mojave Desert" },
        { image: SampleHighlightFive, description: "Using AI to protect the Mojave Desert" },
        { image: SampleHighlightSix, description: "Using AI to protect the Mojave Desert" },
    ]


    return (
        <Box className="container-fluid container-xxxl">
            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row pt-5">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}>Custom Solutions&nbsp; ___</Typography><br></br>
                                <Typography variant="body1" sx={{ color: "#070031", fontSize: "20px", mt: 2, mb: 2 }}>We build software solutions that solve our clients' business challenges</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Grid container spacing={0} sx={{ width: '95%', margin: 'auto' }}>
                {highlightsCollection.map((highlight, index) => {
                    return (
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <HighlightsCard
                                breakpoints={breakpoints}
                                highlight={highlight}
                                index={index}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default CustomSolutionsComponent