import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Grid, ImageList, ImageListItem, Button } from '@mui/material';
import EastIcon from "@mui/icons-material/East";
import collageImage1 from '../../assets/images/contact-us/contact-one.jpg';
import collageImage2 from '../../assets/images/contact-us/contact-nine.jpg';
import collageImage3 from '../../assets/images/contact-us/contact-three.png';
import collageImage4 from '../../assets/images/contact-us/contact-five.jpg';
// import collageImage5 from '../../assets/images/contact-us/contact-five.jpg';
import collageImage6 from '../../assets/images/contact-us/contact-seven.jpg';
import { useNavigate } from 'react-router-dom';

const imageSources = [
    { src: collageImage1, cols: 2, rows: 2 },
    { src: collageImage3, cols: 1, rows: 1 },
    { src: collageImage2, cols: 1, rows: 3 },
    { src: collageImage4, cols: 1, rows: 2 },
    { src: collageImage6, cols: 1, rows: 2 }
];

const ContactUs = () => {
    const navigate = useNavigate();
    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (inView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    return (
        <div ref={sectionRef} >
            <div className="container-fluid container-xxxl" >
                <div class="row">
                    <div class="col-12 px-md-5">
                        <div class="column-card">
                            <div class="row">
                                <div class="col-7 text-left ">
                                    <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >Contact us&nbsp; ___</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center"
                    sx={{
                        height: 'auto',
                        // paddingLeft: 1,
                        // paddingRight: 4,
                        // paddingTop: 2,
                        // paddingBottom: 2,
                        padding: '20px' , paddingTop: 3, paddingBottom: 3
                    }}>
                    <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                            <ImageList cols={3} gap={0} sx={{ width: '95%', margin: 'auto', height: 'auto' }}>
                                {imageSources.map((image, index) => (
                                    <ImageListItem key={index} cols={image.cols} rows={image.rows}>
                                        <img
                                            src={image.src}
                                            alt={`Collage ${index + 1}`}
                                            loading="lazy"
                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography variant="h4" fontWeight="bold" align="left" sx={{ marginBottom: 2, width: '91%', marginRight: '5' }} style={{ color: '#070031' }}>
                            Have a project in mind?
                        </Typography>
                        <Typography variant="body1" align="left" sx={{ marginBottom: 2, width: '91%', marginRight: '5' }} style={{ color: '#070031' }}>
                            Let's hear what you are looking for and we'll help you find the best solution. Get professional help from our team on the discovery stage; we offer technical consultations, analysis and advice on a technology stack for your project.
                        </Typography>
                        <Typography variant="body1" align="left" sx={{ marginBottom: 2, width: '91%', marginRight: '7' }} style={{ color: '#070031' }}>
                            We also provide the best price for web and digital marketing solutions. Start your project with us and make something awesome together!
                        </Typography>
                        <Grid container justifyContent="flex-end">
                            <Button variant="contained" endIcon={<EastIcon sx={{ color: "#fff", }} />}
                                sx={{
                                    borderRadius: "18px", backgroundColor: "#99CC00", fontSize: "16px", textTransform: "none", color: "#fff", marginRight: 10,
                                    "&:hover": { backgroundColor: "#99CC00" }
                                }}
                                onClick={(e) => navigate("/contact-us")}
                            >
                                Get in touch
                            </Button>
                        </Grid>
                        <br /> <br />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default ContactUs;
