import React, { useEffect } from 'react';
import { Box, Link } from "@mui/material";
import Banner from './banner-component';
import WeAreNextix from './we-are-nextix.component';
import MissionVisionValues from './mission-vision-values.component';
import AwardsAndRecognitions from './awards-and-recognitions.component';
import OurOffices from './our-offices.component';
import ContactUs from './contact-us.component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const AboutUsComponent = (props) => {

  const { breakpoints } = props;

      
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#mission-vision') {
      const element = document.getElementById('mission-vision');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        window.history.replaceState(null, '', window.location.pathname);
      }
    } else if (hash === '#leadership-team') {
      const element = document.getElementById('leadership-team');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        window.history.replaceState(null, '', window.location.pathname);
      }
    } else if (hash === '#office-locations') {
      const element = document.getElementById('office-locations');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        window.history.replaceState(null, '', window.location.pathname);
      }
    } 

  }, []);

  return (
    <Box>
      <Banner />
      <Box id="leadership-team"></Box>
      <Box sx={{ mb: 10 }} />
      <WeAreNextix />
      <Box id="mission-vision"></Box>
      <Box sx={{ mb: 10 }} />
      <MissionVisionValues />
      <Box sx={{ mb: 10 }} />
      <AwardsAndRecognitions />
      <Box id="office-locations"></Box>
      <Box sx={{ mb: 10 }} />
      <OurOffices />
      <Box sx={{ mb: 10 }} />
      <ContactUs />
      <Box sx={{mb: 10}} />

      <Box className="container-fluid container-xxxl">
        <div className="row">
          <div className="col-12 px-md-5">
            <div className="column-card">
              <div className="row">
                <div class="socialfollow aem-GridColumn aem-GridColumn--default--12" style={{ textAlign: 'start' }} >
                  <section class="col-12-soc" aria-label="follow us on social media" style={{ display: 'flex', alignItems: 'center', padding: '0px' }} >
                    <div class="base font-weight-normal d-inline align-middle mr-g">
                      Follow Nextix
                    </div> &nbsp;&nbsp;&nbsp;
                    <div class="soc-widget">
                      <Link target="_blank" href="https://www.facebook.com/OVCodeSwitzerlandAG/" underline="none">
                        <i><FontAwesomeIcon icon={faFacebook} /></i>
                      </Link>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Box>
    </Box>
  )
}

export default AboutUsComponent
