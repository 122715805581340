import React, { useRef, useEffect, useState } from 'react';
import { Grid, Typography, Box, Card, CardContent, CardMedia } from "@mui/material";
import missionImage from '../../assets/images/close-up-people-working-as-team.jpg';
import visionImage from '../../assets/images/arrow-pointing-forward.jpg';
import valuesImage from '../../assets/images/marketing-branding-creativity-business-values.jpg';

const MissionCard = ({ image, heading, description }) => (
 <Grid item xs={12} sm={6} md={4}>
    <Card sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', boxShadow: "inherit", borderRadius: 3, border: "ActiveBorder"}}>
      <CardMedia component="img" height="250" image={image} alt={heading} sx={{ width: '100%', margin: 'auto', objectFit: 'cover' }} />
      <CardContent sx={{ padding: '16px', width: '100%', textAlign: 'center' }}>
        <Typography gutterBottom variant="h5" component="div" align="left" sx={{fontWeight: 'bold', color: '#070031', fontSize: "20px"}}>
          {heading}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" align="left" sx={{color: '#070031', fontSize: "16px"}}>
          {description}
        </Typography>
      </CardContent>
    </Card>
 </Grid> 
);

const VisionCard = ({ image, heading, description }) => (
 <Grid item xs={12} sm={6} md={4}>
    <Card sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', padding: 0, margin: 0, boxShadow: "none", borderRadius: 0}}>
      <CardMedia component="img" height="250" image={image} alt={heading} sx={{ width: '100%', margin: 0, padding: 0, objectFit: 'cover' }} />
      <CardContent sx={{ padding: '16px', width: '100%', textAlign: 'center' }}>
        <Typography gutterBottom variant="h5" component="div" align="left" sx={{ fontWeight: 'bold', color: '#070031', fontSize: "20px"}}>
          {heading}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" align="left" sx={{color: '#070031', fontSize: "16px"}}>
          {description}
        </Typography>
      </CardContent>
    </Card>
 </Grid>
);

const ValuesCard = ({ image, heading, description }) => (
 <Grid item xs={12} sm={6} md={4}>
    <Card sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', boxShadow: "none", borderRadius: 0}}>
      <CardMedia component="img" height="250" image={image} alt={heading} sx={{ width: '100%', margin: 'auto', objectFit: 'cover' }} />
      <CardContent sx={{ padding: '16px', width: '100%', textAlign: 'center' }}>
        <Typography gutterBottom variant="h5" component="div" align="left" sx={{fontWeight: 'bold', color: '#070031', fontSize: "20px"}}>
          {heading}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" align="left" sx={{color: '#070031', fontSize: "16px"}}>
          {description}
        </Typography>
      </CardContent>
    </Card>
 </Grid>
);

const MissionVisionValuesImageComponent = () => {
 const sectionRef = useRef(null);
 
 return (
      <div ref={sectionRef} style={{ backgroundColor: '#F2F2F2', padding: '20px', borderRadius: '10px' }}>
        <Grid container justifyContent="center" spacing={4} sx={{ display: 'flex', flexDirection: 'row', mb: 4 }}>
          <MissionCard image={missionImage} heading="Our Mission" description="To improve lives by accelerating social and economic development with trusted platforms." />
          <VisionCard image={visionImage} heading="Our Vision" description="To be the leading social innovation platform" />
          <ValuesCard image={valuesImage} heading="Our Values" description="To uplift lives towards nation building, to be the leading social innovation platform" />
        </Grid>
      </div>
 );
};

export default MissionVisionValuesImageComponent;
