import { Box, Grid, Typography } from "@mui/material";
import NewsEventsBanner from "../../assets/images/news-events-banner.jpg";

const BannerComponent = (props) => {

    const { breakpoints } = props;

    return (
        <Box sx={{ textAlign: 'start' }}>
            <div class="container-fluid container-xxxl">
                <div class="bannerOverlayArea pb-3 pb-md-0">

                    <div className="bannerOverlayMinimizeImage bannerFull"
                        style={{
                            backgroundImage: `url(${NewsEventsBanner})`,
                            backgroundPosition: '50% 10%',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}>
                    </div>

                    <div class="bannerOverlayText col-12  pt-2 pb-3 pb-md-5 pl-md-5" >
                        <div class="bannerTextShortTitle"></div>

                        <div class="bannerTextSubTitle d-none d-md-block">
                            <Typography className="bannerTextShortTitle" sx={{ fontSize: { xs: 30, sm: 40, md: 50 } }}>Resources</Typography>
                        </div>
                        {/* <Typography className="bannerTextLongTitle d-none d-md-block" sx={{ fontSize: { xs: 28, sm: 34 }, mt: 2 }}>
                            We build software solutions that solve our clients' business challenges
                        </Typography> */}

                    </div>

                </div>
            </div>
        </Box>

    )
}

export default BannerComponent