import {Box, Grid} from "@mui/material";
import CareerBanner from "../../assets/images/join-us/join-us-banner.jpg";


const BannerComponent = (props) => {

    const {breakpoints} = props;

    return (
        <Box sx={{ textAlign: 'start' }}>
            <div class="container-fluid container-xxxl">
                <div class="bannerOverlayArea pb-3 pb-md-0">

                    <div className="bannerOverlayMinimizeImage bannerFull"
                        style={{
                            backgroundImage: `url(${CareerBanner})`,
                            backgroundPosition: '50% 55%',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}>
                    </div>

                </div>
            </div>
        </Box>
    )
}

export default BannerComponent