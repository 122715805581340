import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import cebuPeople from '../../assets/images/who-we-are/cebu-dev.jpg';
import makatiPeople from '../../assets/images/who-we-are/makati_one.png';
import makatiAdmin from '../../assets/images/who-we-are/makati_admin.png';
import cebuAdmin from '../../assets/images/who-we-are/cebu_admin.png';
import cebuSupport from '../../assets/images/who-we-are/cebu-support.png';
import makatiMarketing from '../../assets/images/who-we-are/makati-marketing.jpg';

const MeetOurPeopleComponent = () => {
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed

    const itemData = [
        { img: makatiAdmin, title: 'Image 1' , featured: true  },
        { img: cebuPeople, title: 'Image 2' },
        { img: makatiPeople, title: 'Image 3'},
        // { img: cebuSupport, title: 'Image 3' },
        { img: cebuAdmin, title: 'Image 4' },
        { img: makatiMarketing, title: 'Image 5' },
    ];

    return (
        <Grid container spacing={0}>
            {itemData.map((item, index) => (
                <Grid
                    item
                    xs={isSmallScreen ? 12 : item.featured ? 12 : 3}
                    key={index}
                    // onClick={() => navigate('/meet-our-people/career/life-at' + item?.title)}
                    sx={{ cursor: 'pointer' }}
                >
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            paddingTop: item.featured ? '56.25%' : '75%', // 16:9 for featured, 4:3 for others
                            overflow: 'hidden',
                        }}
                    >
                        <img
                            src={item.img}
                            alt={item.title}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};

export default MeetOurPeopleComponent;
