import { Box, Button, Grid, ImageList, ImageListItem, ImageListItemBar, Typography } from "@mui/material"
import MeetOurPeopleComponent from "../meet-our-people/meet-our-people.component"
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";



const MeetOurPeopleLandingComponent = () => {

    const navigate = useNavigate();

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (isInView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);
    

    return (
        <div ref={sectionRef} class="container-fluid container-xxxl" >
            <Grid container sx={{ textAlign: 'start', position: 'relative' }}>
                <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'} >
                            Meet Our People &nbsp; ___
                        </Typography >

                    <br />

                    <div style={{
                        position: 'inherit',
                        bottom: '20px',
                        left: 0,
                        width: '100%',
                        textAlign: 'start'
                    }}>
                        <Typography sx={{ textAlign: 'start', fontSize: { xs: 24, sm: 34, md: 40 }, fontWeight: 'regular', mb: 5, mt: 10, mr: 5}}>
                            We are a diverse team of thinkers and doers, united by a steadfast commitment in serving our customers.
                        </Typography>
                        <Button class="actionButton mt-md-4" onClick={() => window.location.href = '/join-our-team'} >
                            Join Our Team
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                   <MeetOurPeopleComponent />
                </Grid>
            </Grid>
        </div>
    )
}

export default MeetOurPeopleLandingComponent