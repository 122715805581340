import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../assets/logo/nextix-logo.png";
import { useNavigate } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MoreIcon from "@mui/icons-material/MoreVert";
import AppsIcon from "@mui/icons-material/Apps";
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import useWidth from '../hooks/useWidth';
import MobileAppbarComponent from "./mobile-appbar.component";
import WebAppbarComponent from "./web-appbar.component";
import SubMenuComponent from "./sub-menu.component";
import LightModeIcon from '@mui/icons-material/LightMode';



const languageTexts = {
  english: {
    services: "Services",
    solutions: "Solutions",
    resources: "Resources",
    contactUs: "Contact Us",
    aboutUs: "We Are Nextix",
  },

};



function AppbarComponent() {
  const url = window.location.pathname;
  const navigate = useNavigate();
  const breakpoints = useWidth();

  const [value, setValue] = useState(0);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [weAreNextixAnchorEl, setWeAreNextixAnchorEl] = useState(null);
  const [weAreNextixMenuOpen, setWeAreNextixMenuOpen] = useState(false);
  const [servicesAnchorEl, setServicesAnchorEl] = useState(null);
  const [servicesMenuOpen, setServicesMenuOpen] = useState(false);
  const [solutionsAnchorEl, setSolutionsAnchorEl] = useState(null);
  const [solutionsMenuOpen, setSolutionsMenuOpen] = useState(false);
  const [resourcesAnchorEl, setResourcesAnchorEl] = useState(null);
  const [resourcesMenuOpen, setResourcesMenuOpen] = useState(false);
  const [contactUsAnchorEl, setContactUsAnchorEl] = useState(null);
  const [contactUsMenuOpen, setContactUsMenuOpen] = useState(false);


 
  // Rendered text based on selected language
  const text = languageTexts['english'];

 
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleWeAreNextixMenuOpen = (event) => {
    setWeAreNextixAnchorEl(event.currentTarget);
    setWeAreNextixMenuOpen(true);
  };

  const handleWeAreNextixMenuClose = () => {
    setWeAreNextixAnchorEl(null);
    setWeAreNextixMenuOpen(false);
  };

  const handleServicesMenuOpen = (event) => {
    setServicesAnchorEl(event.currentTarget);
    setServicesMenuOpen(true);
  };

  const handleServiceMenuClose = () => {
    setServicesAnchorEl(null);
    setServicesMenuOpen(false);
  };

  const handleSolutionsMenuOpen = (event) => {
    setSolutionsAnchorEl(event.currentTarget);
    setSolutionsMenuOpen(true);
  };

  const handleSolutionsMenuClose = () => {
    setSolutionsAnchorEl(null);
    setSolutionsMenuOpen(false);
  };

  const handleResourcesMenuOpen = (event) => {
    setResourcesAnchorEl(event.currentTarget);
    setResourcesMenuOpen(true);
  };

  const handleResourcesMenuClose = () => {
    setResourcesAnchorEl(null);
    setResourcesMenuOpen(false);
  };

  const handleContactUsMenuOpen = (event) => {
    setContactUsAnchorEl(event.currentTarget);
    setContactUsMenuOpen(true);
  };

  const handleContactUsMenuClose = () => {
    setContactUsAnchorEl(null);
    setContactUsMenuOpen(false);
  };

  
  // useEffect(() => {
  //   const segments = url.split("/");
  //   const lastSegment = segments[segments.length - 1];
  //   console.log("lastSegmentxxx", lastSegment)

  //   if (lastSegment === "who-we-are") {
  //     setSelectedWhoWeAre("who-we-are");
  //   } else if (lastSegment === "missionVisionValues") {
  //     setSelectedWhoWeAre("missionVisionValues");
  //   } else if (lastSegment === "our-business") {
  //     setSelectedWhoWeAre("our-business");
  //   } else if (lastSegment === "social-impact") {
  //     setSelectedWhoWeAre("social-impact");
  //   } else if (lastSegment === "sustainability") {
  //     setSelectedWhoWeAre("sustainability");
  //   } else if (lastSegment === "contactUs") {
  //     setSelectedContactUs("contactUs");
  //   } 
  //    else if (lastSegment === "domain-operations") {
  //     setSelectedWhatWeDo("domain-operations")
  //   } else if (lastSegment === "autonomy-ai") {
  //     setSelectedWhatWeDo("autonomy-ai")
  //   } else if (lastSegment === "cyber") {
  //     setSelectedWhatWeDo("cyber")
  //   } else if (lastSegment === "deference-capability") {
  //     setSelectedWhatWeDo("deference-capability")
  //   } else if (lastSegment === "transformative-technology") {
  //     setSelectedWhatWeDo("transformative-technology")
  //   } else if (lastSegment === "ahead-of-ready") {
  //     setSelectedWhatWeDo("ahead-of-ready")
  //   } else if (lastSegment === "news") {
  //     setSelectedNews("news")
  //   } else {
  //     setSelectedWhoWeAre(null);
  //     setSelectedContactUs(null);
  //     setSelectedWhatWeDo(null);
  //     setSelectedNews(null);
  //   }

  // }, [url]);


  const mobileMenuId = 'primary-search-account-menu-mobile';

  return (
    <AppBar
    position="fixed"
    sx={{
      zIndex: (theme) => theme.zIndex.drawer + 1,
      top: 0,
      left: "auto",
      right: 0,
      color: "rgb(255, 255, 255)",
      backgroundColor: "#fff",
      boxShadow: "none",
      width: "100%", 
    }}
  >
      <div className="containerMain">
        <Toolbar sx={{ width: "100%", paddingLeft: '0px !important', paddingRight: '0px !important', paddingTop: 1 }} >
          <Button
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
              mr: 2,
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              padding: '0px',
            }}
            disableRipple
          >
            <img
              alt=""
              src={logo}
              onClick={(e) => (window.location.href = "/")}
              style={{
                height: breakpoints === 'xs' || breakpoints === 'sm' ? 30 : 50,
                verticalAlign: "middle",
                cursor: "pointer",
                imageRendering: "pixelated",
                imageRendering: "-webkit-optimize-contrast",
              }}
            />
          </Button>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <WebAppbarComponent
              text={text}
              handleWeAreNextixMenuOpen={handleWeAreNextixMenuOpen}
              handleServicesMenuOpen={handleServicesMenuOpen}
              handleSolutionsMenuOpen={handleSolutionsMenuOpen}
              handleResourcesMenuOpen={handleResourcesMenuOpen}
              handleContactUsMenuOpen={handleContactUsMenuOpen}
            />
          </Box>


          <Box sx={{ flexGrow: 1 }} />

              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color={"black"}
                sx={{display: { xs: "flex", md: "none" }}}
              >
                <AppsIcon />
              </IconButton>

            <Button sx={{ marginTop: '.8em', color: '#000', height: '30px', display: { xs: "none", md: "flex" } }} >
              <LightModeIcon />
            </Button>

        </Toolbar>
      </div>
      
      {/* Sub-Menus */}
        <SubMenuComponent
          weAreNextixAnchorEl={weAreNextixAnchorEl}
          weAreNextixMenuOpen={weAreNextixMenuOpen}
          handleWeAreNextixMenuClose={handleWeAreNextixMenuClose}
          servicesAnchorEl={servicesAnchorEl}
          servicesMenuOpen={servicesMenuOpen}
          handleServiceMenuClose={handleServiceMenuClose}
          solutionsAnchorEl={solutionsAnchorEl}
          solutionsMenuOpen={solutionsMenuOpen}
          handleSolutionsMenuClose={handleSolutionsMenuClose}
          resourcesAnchorEl={resourcesAnchorEl}
          resourcesMenuOpen={resourcesMenuOpen}
          handleResourcesMenuClose={handleResourcesMenuClose}
          contactUsAnchorEl={contactUsAnchorEl}
          contactUsMenuOpen={contactUsMenuOpen}
          handleContactUsMenuClose={handleContactUsMenuClose}
      />


      <MobileAppbarComponent
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        handleMobileMenuClose={handleMobileMenuClose}
        mobileMenuId={mobileMenuId}
      />

    </AppBar>


  );
}

export default AppbarComponent;