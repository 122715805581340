import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography, Card, CardMedia, CardContent } from '@mui/material';
import OfficeOne from '../../assets/images/offices/cebu-site.jpg';
import OfficeTwo from '../../assets/images/landmarks-hong-kong.jpg';
import PhoneIcon from './../../assets/icons/phone.png';
import PinIcon from './../../assets/icons/pin.png'; 
import MailIcon from './../../assets/icons/email.png'; 

const OurOfficesCard = () => {
    return (
        <Grid container spacing={2} justifyContent="center">
            {/* First Card */}
            <Grid item xs={12} sm={6}>
            <Card sx={{ maxWidth: '80%', width: '100%', margin: 'auto'}}>
                    <CardMedia
                        component="img"
                        height="280"
                        image={OfficeOne}
                        alt="Office Image"
                    />
                    <CardContent>
                        <Grid container direction="column" alignItems="flex-start">
                            <Grid item>
                                <img src={PinIcon} alt="Location" sx={{ width: '20px', height: '24px' }} /> 5 Ma. Cristina St. Brgy. Capitol Site, Cebu City 6000 Philippines
                            </Grid>
                            <Grid item>
                                <img src={PhoneIcon} alt="Contact" sx={{ width: '24px', height: '24px' }} /> +63 (32) 384-9309 
                            </Grid>
                            <Grid item>
                                <img src={MailIcon} alt="Email" sx={{ width: '24px', height: '24px' }} /> info@nextix.co
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            
            {/* Second Card */}
            <Grid item xs={12} sm={6} >
                <Card sx={{ maxWidth: '80%', width: '100%', margin: 'auto'}}>
                    <CardMedia
                        component="img"
                        height="280"
                        image={OfficeTwo}
                        alt="Office Image"
                    />
                    <CardContent>
                        <Grid container direction="column" alignItems="flex-start">
                            <Grid item>
                                <img src={PinIcon} alt="Location" sx={{ width: '24px', height: '24px' }} /> 3rd Floor Montivar Bldg., 34 Jupiter St., Brgy., Bel-air, Makati City 1200
                            </Grid>
                            <Grid item>
                                <img src={PhoneIcon} alt="Contact" sx={{ width: '24px', height: '24px' }} /> +63 (2) 8828-1011, 0915 926 2398
                            </Grid>
                            <Grid item>
                                <img src={MailIcon} alt="Email" sx={{ width: '24px', height: '24px' }} /> info@nextix.co
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

const OurOffices = () => {
    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (isInView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    return (
        <div ref={sectionRef}>
        <Box sx={{ backgroundColor: '#f2f2f2' }}>
            <br/>
            <div class="container-fluid container-xxxl">
            <div class="row">
                    <div class="col-12 px-md-5">
                        <div class="column-card">
                            <div class="row">
                                <div class="col-7 text-left ">
                                    <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >Our Offices&nbsp; ___</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br/>
                
                <OurOfficesCard/>

            </div>
            <br/>
        </Box>
        </div>
    )
};

export default OurOffices;
