import React, { useEffect, useState } from "react";
import { CssBaseline, Box } from "@mui/material";
import Appbar from "./appbar.component";
import { useLocation } from "react-router-dom";
import useWidth from "../hooks/useWidth";
import FooterComponent from "./footer.component";


function Main({ children }) {
    const url = window.location.pathname;
    const breakpoints = useWidth();

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: url === "/view/pdf" ? "#80808021" : {},
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    // maxWidth: url === "/" ? {} : static_variable.mainContainer_maxWidth,
                    overflowY: breakpoints === "xs" || breakpoints === "sm" ? {} : "hidden",
                    height: "100%",
                }}
            >
                {children}
            </Box>
        </Box>
    );
}

function MainLayout({ children }) {
    const location = useLocation();
    const { pathname } = location;

    return (
        <>
            <CssBaseline />
            
            <Appbar />

            <Main>
                <Box>
                    {children}
                </Box>
            </Main>

            <FooterComponent />

        </>
    );
}

export default MainLayout;
