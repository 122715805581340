import React, {useState} from "react";
import {Grid, Typography, Card, CardContent} from "@mui/material";
import SampleHighlight from "../../assets/images/sample-highlight.jpg";
import SampleHighlightTwo from "../../assets/images/sample-highlight2.png";
import SampleHighlightThree from "../../assets/images/sample-highlight3.jpg";
import SampleHighlightFour from "../../assets/images/sample-highlight.jpg";
import SampleHighlightFive from "../../assets/images/sample-highlight2.png";
import SampleHighlightSix from "../../assets/images/sample-highlight3.jpg";

function HighlightsCard (props) {

    const {highlight, breakpoints} = props;

    const [isImageHovered, setImageHovered] = useState(false);

    const handleMouseEnter = () => {
        setImageHovered(true);
    }
  
    const handleMouseLeave = () => {
        setImageHovered(false);
    }

    return (
        <Card sx={{borderRadius: 0, height: "400px"}}>
            <CardContent sx={{textAlign: "start", color: "#070031", padding: "2px"}}>
                <img alt="" src={highlight.image} 
                    style={{
                        width: "100%", 
                        height: "400px", 
                        objectFit: "cover",
                        transition: "transform 2s ease", 
                        transform: (isImageHovered) ? "scale(1.2)" : "scale(1)"
                    }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />

                <Typography sx={{fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "16px" : "18px", position: "absolute", color: "#fff", mt: "-45px", ml: "16px", opacity: "95%"}}>
                    {highlight.description}
                </Typography>       
            </CardContent> 
        </Card>
    )
}

const HighlightsComponent = (props) => {

    const {breakpoints} = props;

    const highlightsCollection = [
        {image: SampleHighlight, description: "Using AI to protect the Mojave Desert"},
        {image: SampleHighlightTwo, description: "Using AI to protect the Mojave Desert"},
        {image: SampleHighlightThree, description: "Using AI to protect the Mojave Desert"},
        {image: SampleHighlightFour, description: "Using AI to protect the Mojave Desert"},
        {image: SampleHighlightFive, description: "Using AI to protect the Mojave Desert"},
        {image: SampleHighlightSix, description: "Using AI to protect the Mojave Desert"},
    ]


    return (
        <Grid container direction="row" sx={{backgroundColor: "#f3f3f3", width: "91%", margin: "auto", mt: "-10px", paddingBottom: "32px"}}>
            <Grid item xs={12} sx={{textAlign: "center"}}>
                <Typography variant="h6" sx={{color: "#070031", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "22px" : "30px", fontWeight: "bold", pt: "10px"}}>
                    Highlights
                </Typography>

                <Typography variant="h6" sx={{color: "#070031", fontSize: "16px", pb: "20px", mt: -1}}>
                    In case you’d like to know more:
                </Typography>
            </Grid>

            <Grid container spacing={0} sx={{}}>
                {highlightsCollection.map((highlight, index) => {
                    return (
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <HighlightsCard 
                                breakpoints={breakpoints} 
                                highlight={highlight} 
                                index={index}
                            />
                        </Grid>
                    )})
                }
            </Grid>
        </Grid>
    )
}

export default HighlightsComponent