import { Box, Menu, MenuItem } from "@mui/material"

const SubMenuComponent = (props) => {

  const {
    weAreNextixAnchorEl,
    weAreNextixMenuOpen,
    handleWeAreNextixMenuClose,
    servicesAnchorEl,
    servicesMenuOpen,
    handleServiceMenuClose,
    solutionsAnchorEl,
    solutionsMenuOpen,
    handleSolutionsMenuClose,
    resourcesAnchorEl,
    resourcesMenuOpen,
    handleResourcesMenuClose,
    contactUsAnchorEl,
    contactUsMenuOpen,
    handleContactUsMenuClose,
  } = props;

  return (
    <Box>
      {weAreNextixMenuOpen &&
        <Menu
          anchorEl={weAreNextixAnchorEl}
          open={Boolean(weAreNextixMenuOpen)}
          onClose={handleWeAreNextixMenuClose}
        >
          <MenuItem onClick={() => window.location.href = '/who-we-are'} >Our Story</MenuItem>
          <MenuItem onClick={() => window.location.href = '/who-we-are#mission-vision'} >Mision and Vision</MenuItem>
          <MenuItem onClick={() => window.location.href = '/who-we-are#leadership-team'} >Leadership Team</MenuItem>
          <MenuItem onClick={() => window.location.href = '/career'} >Careers</MenuItem>
        </Menu>
      }

      {servicesMenuOpen &&
        <Menu
          anchorEl={servicesAnchorEl}
          open={Boolean(servicesMenuOpen)}
          onClose={handleServiceMenuClose}
        >
          <MenuItem >Digital Transformation</MenuItem>
          <MenuItem >Software Development</MenuItem>
          <MenuItem >Cloud Services</MenuItem>
          <MenuItem>Data Analytics</MenuItem>
          <MenuItem>Cybersecurity</MenuItem>
        </Menu>
      }

      {solutionsMenuOpen &&
        <Menu
          anchorEl={solutionsAnchorEl}
          open={Boolean(solutionsMenuOpen)}
          onClose={handleSolutionsMenuClose}
        >
          <MenuItem onClick={() => window.location.href = '/what-we-do'}>Industry Solutions</MenuItem>
          <MenuItem onClick={() => window.location.href = '/what-we-do#custom-solutions'}>Customs Solutions</MenuItem>
          <MenuItem onClick={() => window.location.href = '/what-we-do#success-stories'}>Success Stories</MenuItem>
        </Menu>
      }

      {resourcesMenuOpen &&
        <Menu
          anchorEl={resourcesAnchorEl}
          open={Boolean(resourcesMenuOpen)}
          onClose={handleResourcesMenuClose}
        >
          <MenuItem onClick={() => window.location.href = 'resources'}>Blog</MenuItem>
          <MenuItem onClick={() => window.location.href = 'resources#whitepapers'}>Whitepapers</MenuItem>
          <MenuItem>Webinars</MenuItem>
        </Menu>
      }

      {contactUsMenuOpen &&
        <Menu
          anchorEl={contactUsAnchorEl}
          open={Boolean(contactUsMenuOpen)}
          onClose={handleContactUsMenuClose}
        >
          <MenuItem onClick={() => window.location.href = '/contact-us'}>Contact Form</MenuItem>
          <MenuItem onClick={() => window.location.href = '/who-we-are#office-locations'}>Office Locations</MenuItem>
          <MenuItem>Support</MenuItem>
        </Menu>
      }

    </Box>
  )
}

export default SubMenuComponent