import React from "react";
import { Grid, Box, Typography, Card, Button } from "@mui/material";
import EmployeeResponsibilityIcon from "../../assets/icons/teamwork.png";
import EducationIcon from "../../assets/icons/education.png";
import CommunityIcon from "../../assets/icons/community.png";
import LeadershipIcon from "../../assets/icons/leadership.png";
import JoinUsSegmentImage from "../../assets/images/join-us-segment.jpg";
import EastIcon from "@mui/icons-material/East";
import GreenBoxIcon from "../../assets/icons/green-box-bullet.png";
import { useNavigate } from "react-router-dom";

const WhyJoinUsComponent = (props) => {

    const { breakpoints } = props;
    const navigate = useNavigate();


    return (
        <Box className="container-fluid container-xxxl">
            <Grid container direction="row" spacing={2} sx={{ backgroundColor: "#f3f3f3", width: "95%", margin: "auto", mt: "-0px", pb: 4 }}>
                <Grid item xs={12} sx={{ textAlign: "start", pl: "0px !important" }}>
                    <Typography variant="h6" sx={{ color: "#070031", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "22px" : "30px", fontWeight: "bold", pb: "20px" }}>
                        Why Join Us?
                    </Typography>

                    <Typography variant="h6" sx={{ color: "#070031", fontSize: "16px", pb: "20px", mt: -1 }}>
                        At Nextix, we are proud to work together to imagine innovative solutions that contribute to building a future that is safer, greener and more
                        inclusive. A future that we can all trust. Work within a healthy working environment with a very supportive team of excellent programmers,
                        developers, architects, salespersons, testers, designers, and managers at Nextix.
                    </Typography>
                </Grid>

                <Grid>
                    <Typography variant="h6" sx={{ textAlign: "start", color: "#070031", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? "22px" : "30px", fontWeight: "bold", pb: "20px" }}>
                        Corporate Social Responsibility
                    </Typography>
                </Grid>

                <Grid container sx={{ mb: "28px" }}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#070031", display: "flex", alignItems: "center", padding: (breakpoints == "xs" || breakpoints == "sm") ? "20px" : {} }}>
                        <Box sx={{ display: "flex", justifyContent: "center", ml: (breakpoints == "xs" || breakpoints == "sm") ? {} : "36px" }}>
                            <img alt="" src={EmployeeResponsibilityIcon} style={{ marginTop: (breakpoints == "xs" || breakpoints == "sm") ? {} : "-5px", width: (breakpoints == "xs" || breakpoints == "sm") ? 25 : 30, height: (breakpoints == "xs" || breakpoints == "sm") ? 25 : 30, marginRight: "10px" }} />

                            <Typography sx={{ color: "#fff", fontSize: "18px" }}>
                                Social Responsibility for our Employees
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ textAlign: "start", padding: "0px 10px 0px 20px", mt: (breakpoints == "xs" || breakpoints == "sm") ? 1 : {} }}>
                        <div style={{ display: "flex" }}>
                            <img alt="" src={GreenBoxIcon} style={{ width: 10, height: 10, marginTop: "10px" }} />

                            <Typography sx={{ fontSize: "16px", color: "#6A6B6C", ml: "10px" }}>
                                Compliance with the law and the legal systems is a fundamental principle for next|ix
                            </Typography>
                        </div>

                        <div style={{ display: "flex" }}>
                            <img alt="" src={GreenBoxIcon} style={{ width: 10, height: 10, marginTop: "10px" }} />

                            <Typography sx={{ fontSize: "16px", color: "#6A6B6C", ml: "10px" }}>
                                It operates according to the legal system of the location of registration we adhere to all aspects of employee legislation for each of our locations and for every employee
                            </Typography>
                        </div>

                        <div style={{ display: "flex" }}>
                            <img alt="" src={GreenBoxIcon} style={{ width: 10, height: 10, marginTop: "10px" }} />

                            <Typography sx={{ fontSize: "16px", color: "#6A6B6C", ml: "10px" }}>
                                It operates according to the legal system of the location of registration we adhere to all aspects of employee legislation for each of our locations and for every employee
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid container sx={{ mb: "28px" }}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#070031", display: "flex", alignItems: "center", padding: (breakpoints == "xs" || breakpoints == "sm") ? "20px" : {} }}>
                        <Box sx={{ display: "flex", justifyContent: "center", ml: (breakpoints == "xs" || breakpoints == "sm") ? {} : "36px" }}>
                            <img alt="" src={EducationIcon} style={{ marginTop: (breakpoints == "xs" || breakpoints == "sm") ? {} : "-5px", width: (breakpoints == "xs" || breakpoints == "sm") ? 25 : 30, height: (breakpoints == "xs" || breakpoints == "sm") ? 25 : 30, marginRight: "10px" }} />

                            <Typography sx={{ color: "#fff", fontSize: "18px" }}>
                                Education and Training
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ textAlign: "start", padding: "0px 10px 0px 20px", mt: (breakpoints == "xs" || breakpoints == "sm") ? 1 : {} }}>
                        <div style={{ display: "flex" }}>
                            <img alt="" src={GreenBoxIcon} style={{ width: 10, height: 10, marginTop: "10px" }} />

                            <Typography sx={{ fontSize: "16px", color: "#6A6B6C", ml: "10px" }}>
                                We believe that career development as well as personal growth opens new horizons and level of satisfaction for individuals.
                            </Typography>
                        </div>

                        <div style={{ display: "flex" }}>
                            <img alt="" src={GreenBoxIcon} style={{ width: 10, height: 10, marginTop: "10px" }} />

                            <Typography sx={{ fontSize: "16px", color: "#6A6B6C", ml: "10px" }}>
                                We support any educational initiative from our specialists by providing them with extra finances and spare time for self-development
                            </Typography>
                        </div>

                        <div style={{ display: "flex" }}>
                            <img alt="" src={GreenBoxIcon} style={{ width: 10, height: 10, marginTop: "10px" }} />

                            <Typography sx={{ fontSize: "16px", color: "#6A6B6C", ml: "10px" }}>
                                It operates according to the legal system of the location of registration we adhere to all aspects of employee legislation for each of our locations and for every employee
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid container sx={{ mb: "28px" }}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#070031", display: "flex", alignItems: "center", padding: (breakpoints == "xs" || breakpoints == "sm") ? "20px" : {} }}>
                        <Box sx={{ display: "flex", justifyContent: "center", ml: (breakpoints == "xs" || breakpoints == "sm") ? {} : "36px" }}>
                            <img alt="" src={CommunityIcon} style={{ marginTop: (breakpoints == "xs" || breakpoints == "sm") ? {} : "-5px", width: (breakpoints == "xs" || breakpoints == "sm") ? 25 : 30, height: (breakpoints == "xs" || breakpoints == "sm") ? 25 : 30, marginRight: "10px" }} />

                            <Typography sx={{ color: "#fff", fontSize: "18px" }}>
                                Community
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ textAlign: "start", padding: "0px 10px 0px 20px", mt: (breakpoints == "xs" || breakpoints == "sm") ? 1 : {} }}>
                        <div style={{ display: "flex" }}>
                            <img alt="" src={GreenBoxIcon} style={{ width: 10, height: 10, marginTop: "10px" }} />

                            <Typography sx={{ fontSize: "16px", color: "#6A6B6C", ml: "10px" }}>
                                Compliance with the law and the legal systems is a fundamental principle for next|ix
                            </Typography>
                        </div>

                        <div style={{ display: "flex" }}>
                            <img alt="" src={GreenBoxIcon} style={{ width: 10, height: 10, marginTop: "10px" }} />

                            <Typography sx={{ fontSize: "16px", color: "#6A6B6C", ml: "10px" }}>
                                It operates according to the legal system of the location of registration we adhere to all aspects of employee legislation for each of our locations and for every employee
                            </Typography>
                        </div>

                        <div style={{ display: "flex" }}>
                            <img alt="" src={GreenBoxIcon} style={{ width: 10, height: 10, marginTop: "10px" }} />

                            <Typography sx={{ fontSize: "16px", color: "#6A6B6C", ml: "10px" }}>
                                It operates according to the legal system of the location of registration we adhere to all aspects of employee legislation for each of our locations and for every employee
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid container sx={{ mb: "28px" }}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#070031", display: "flex", alignItems: "center", padding: (breakpoints == "xs" || breakpoints == "sm") ? "20px" : {} }}>
                        <Box sx={{ display: "flex", justifyContent: "center", ml: (breakpoints == "xs" || breakpoints == "sm") ? {} : "36px" }}>
                            <img alt="" src={LeadershipIcon} style={{ marginTop: (breakpoints == "xs" || breakpoints == "sm") ? {} : "-5px", width: (breakpoints == "xs" || breakpoints == "sm") ? 25 : 30, height: (breakpoints == "xs" || breakpoints == "sm") ? 25 : 30, marginRight: "10px" }} />

                            <Typography sx={{ color: "#fff", fontSize: "18px" }}>
                                Sustainability Management and Leadership
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ textAlign: "start", padding: "0px 10px 0px 20px", mt: (breakpoints == "xs" || breakpoints == "sm") ? 1 : {} }}>
                        <div style={{ display: "flex" }}>
                            <img alt="" src={GreenBoxIcon} style={{ width: 10, height: 10, marginTop: "10px" }} />

                            <Typography sx={{ fontSize: "16px", color: "#6A6B6C", ml: "10px" }}>
                                Compliance with the law and the legal systems is a fundamental principle for next|ix
                            </Typography>
                        </div>

                        <div style={{ display: "flex" }}>
                            <img alt="" src={GreenBoxIcon} style={{ width: 10, height: 10, marginTop: "10px" }} />

                            <Typography sx={{ fontSize: "16px", color: "#6A6B6C", ml: "10px" }}>
                                It operates according to the legal system of the location of registration we adhere to all aspects of employee legislation for each of our locations and for every employee
                            </Typography>
                        </div>

                        <div style={{ display: "flex" }}>
                            <img alt="" src={GreenBoxIcon} style={{ width: 10, height: 10, marginTop: "10px" }} />

                            <Typography sx={{ fontSize: "16px", color: "#6A6B6C", ml: "10px" }}>
                                It operates according to the legal system of the location of registration we adhere to all aspects of employee legislation for each of our locations and for every employee
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ pl: "0px !important" }}>
                    <Card sx={{
                        borderRadius: 0,
                        boxShadow: "rgb(100 100 111 / 0%) 0px 7px 29px 0px",
                        overflow: "hidden",
                    }}
                    >
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <img alt="" src={JoinUsSegmentImage}
                                    style={{
                                        width: "100%",
                                        padding: (breakpoints == "xs" || breakpoints == "sm") ? {} : "40px 40px 38px 40px",
                                        height: (breakpoints == "xs" || breakpoints == "sm") ? "100% !important" : "400px",
                                        objectFit: (breakpoints == "xs" || breakpoints == "sm") ? "contain" : "cover"
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ padding: (breakpoints == "xs" || breakpoints == "sm") ? "16px 24px 20px 24px !important" : {} }}>
                                <Box sx={{ display: "block", textAlign: "start", mt: (breakpoints == "xs" || breakpoints == "sm") ? {} : "60px" }}>
                                    <Typography sx={{ color: "#070031", fontWeight: "bold", fontSize: "20px", pt: (breakpoints == "xs" || breakpoints == "sm") ? {} : "35px" }}>
                                        Together we make a difference
                                    </Typography>

                                    <Typography sx={{ fontSize: "16px", color: "#070031", textAlign: "start", padding: "15px 20px 0px 0px" }}>
                                        At Nextix, we re looking for the brightest and most innovative minds to
                                        help build a safer,and more connected world. Discover how your skills
                                        can help us better the world we share and discover the never before.
                                    </Typography>

                                    <Typography sx={{ fontSize: "16px", color: "#070031", textAlign: "start", padding: "30px 20px 40px 0px" }}>
                                        Lets rise to the challenge of the moment together.
                                    </Typography>

                                    <Grid sx={{ textAlign: "end", mr: "26px" }}>
                                        <Button variant="contained" endIcon={<EastIcon sx={{ color: "#fff" }} />}
                                            sx={{
                                                borderRadius: "18px", backgroundColor: "#99CC00", fontSize: "16px", textTransform: "none", color: "#fff",
                                                "&:hover": { backgroundColor: "#99CC00" }
                                            }}
                                            onClick={(e) => navigate('/join-our-team')}
                                        >
                                            Join Us
                                        </Button>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default WhyJoinUsComponent