import React, { useEffect, useRef, useState } from "react";
import { Grid, Box, Typography, Card, Avatar } from "@mui/material";
import SampleSpecialist from "../../assets/images/sample-client2.png";
import SampleSpecialist2 from "../../assets/images/sample-specialist.jpg";
import PhoneIcon from "../../assets/icons/phone.png";
import EmailIcon from "../../assets/icons/email.png";

const DiscussProjectComponent = (props) => {

    const { breakpoints } = props;

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (inView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);


    return (
        <div ref={sectionRef} style={{ backgroundColor: "#f3f3f3" }}>
            <div class="container-fluid container-xxxl">
                <div class="row">
                    <div class="col-12 px-md-5">
                        <div class="column-card">
                            <div class="row pt-5">
                                <div class="col-7 text-left ">
                                    <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  > Discuss your project&nbsp; ___</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Grid container spacing={4} direction="row" justifyContent="flex-start" alignItems="center" sx={{ height: 'auto', padding: 4, paddingTop: 3, paddingBottom: 3 }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card sx={{
                            borderRadius: 0,
                            boxShadow: "rgb(100 100 111 / 0%) 0px 7px 29px 0px",
                            overflow: "hidden",
                            padding: "30px",
                        }}
                        >
                            <Grid container direction="row">
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Avatar alt="" src={SampleSpecialist}
                                        sx={{ width: (breakpoints == "xs" || breakpoints == "sm") ? 100 : 150, height: (breakpoints == "xs" || breakpoints == "sm") ? 100 : 150, border: "6px solid #99CC00" }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ mt: "13px" }}>
                                    <Box sx={{ display: "block", textAlign: "start" }}>
                                        <Typography sx={{ color: "#070031", fontWeight: "bold", fontSize: "18px" }}>
                                            Van Gogh
                                        </Typography>

                                        <Typography sx={{ color: "#6A6B6C", fontSize: "16px", mt: "-5px" }}>
                                            Head of Sales & Marketing
                                        </Typography>
                                    </Box>

                                    <Grid sx={{ display: "block", textAlign: "start", mt: "14px" }}>
                                        <div style={{ display: "flex" }}>
                                            <img alt="" src={EmailIcon} style={{ width: 15, height: 15, marginTop: "4px", marginRight: "7px" }} />
                                            <Typography sx={{ color: "#070031", fontSize: "16px" }}>vangogh@nextix.co</Typography>
                                        </div>

                                        <div style={{ display: "flex" }}>
                                            <img alt="" src={PhoneIcon} style={{ width: 15, height: 15, marginTop: "4px", marginRight: "7px" }} />
                                            <Typography sx={{ color: "#070031", fontSize: "16px" }}>+63912345678</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Card sx={{
                            borderRadius: 0,
                            boxShadow: "rgb(100 100 111 / 0%) 0px 7px 29px 0px",
                            overflow: "hidden",
                            padding: "30px",
                        }}
                        >
                            <Grid container direction="row">
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Avatar alt="" src={SampleSpecialist2}
                                        sx={{ width: (breakpoints == "xs" || breakpoints == "sm") ? 100 : 150, height: (breakpoints == "xs" || breakpoints == "sm") ? 100 : 150, border: "6px solid #99CC00" }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ mt: "13px" }}>
                                    <Box sx={{ display: "block", textAlign: "start" }}>
                                        <Typography sx={{ color: "#070031", fontWeight: "bold", fontSize: "18px" }}>
                                            Kim Seokjin
                                        </Typography>

                                        <Typography sx={{ color: "#6A6B6C", fontSize: "16px", mt: "-5px" }}>
                                            Project Manager
                                        </Typography>
                                    </Box>

                                    <Grid sx={{ display: "block", textAlign: "start", mt: "14px" }}>
                                        <div style={{ display: "flex" }}>
                                            <img alt="" src={EmailIcon} style={{ width: 15, height: 15, marginTop: "4px", marginRight: "7px" }} />
                                            <Typography sx={{ color: "#070031", fontSize: "16px" }}>kimseokjin@nextix.co</Typography>
                                        </div>

                                        <div style={{ display: "flex" }}>
                                            <img alt="" src={PhoneIcon} style={{ width: 15, height: 15, marginTop: "4px", marginRight: "7px" }} />
                                            <Typography sx={{ color: "#070031", fontSize: "16px" }}>+63912048111</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default DiscussProjectComponent