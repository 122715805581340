import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography, Card, CardMedia, CardContent } from '@mui/material';
import OfficeOne from '../../assets/images/empty-office-workplace-with-table-chair-computer.jpg';
import OfficeTwo from '../../assets/images/landmarks-hong-kong.jpg';

const IndustryPortfoliosCard = ({ content, readMoreLinks }) => {
    return (
        <Grid container spacing={0} justifyContent="center">
            {content.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card sx={{ maxWidth: '85%', width: '90%', margin: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' }}>
                        <CardMedia
                            component="img"
                            height="250"
                            image={item.image}
                            alt="Office Image"
                        />
                        <CardContent>
                            <Grid container direction="column" alignItems="flex-start">
                                <Grid item>
                                    <b>{item.title}</b>
                                </Grid>
                                <Grid item>
                                    <a href={readMoreLinks[index]} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', fontWeight: 'bold', color: '#070031' }}>Read More</a>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

const IndustrySolutionsComponent = () => {
    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (isInView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    // Content for the first row of cards
    const firstRowContent = [
        {
            image: OfficeOne,
            title: 'Digital Identity Solutions',
        },
        {
          image: OfficeOne,
          title: 'Government Systems',
        },
        {
          image: OfficeOne,
          title: 'Secure IoT Solutions',
        }
    ];

    // Links for the first row of cards
    const firstRowLinks = [
        'link-to-digital-identity-solutions',
        'link-to-government-systems',
        'link-to-secure-iot-solutions'
    ];

    // Content for the second row of cards
    const secondRowContent = [
        {
            image: OfficeTwo,
            title: 'Financial Technology',
        },
        {
            image: OfficeTwo,
            title: 'FinTech',
        },
        {
            image: OfficeTwo,
            title: 'Authentication and Verification',
        },
    ];

    // Links for the second row of cards
    const secondRowLinks = [
        'link-to-financial-technology',
        'link-to-fintech',
        'link-to-authentication-and-verification'
    ];

    return (
        <div ref={sectionRef} style={{backgroundColor: '#f2f2f2', paddingBottom: 60}}>
            <Box sx={{ minHeight: '40vh' }}>
                <div class="container-fluid container-xxxl">
                    <div class="row">
                        <div class="col-12 px-md-5">
                            <div class="column-card">
                                <div class="row pt-5">
                                    <div class="col-7 text-left ">
                                        <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}>Insustry Solutions&nbsp; ___</Typography><br></br>
                                        <Typography variant="body1" sx={{ color: "#070031",  fontSize: "20px", mt: 2, mb: 2 }}>We build software solutions that solve our clients' business challenges</Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <IndustryPortfoliosCard content={firstRowContent} readMoreLinks={firstRowLinks}/> 
                    {/* Add space between rows */}
                    <div style={{ marginBottom: '50px' }}></div> {/* Adjust the margin as per your requirement */}
                    <IndustryPortfoliosCard content={secondRowContent} readMoreLinks={secondRowLinks} style={{ marginBottom: '20px' }} />
                </div>
            </Box>
        </div>
    );
};

export default IndustrySolutionsComponent;
