import LandingScreen from "../screen/LandingScreen";
import WhatWeDoScreen from "../screen/WhatWeDoScreen";
import PeopleStoriesComponent from "../components/meet-our-people/people-stories.component";
import NewsScreen from "../screen/NewsScreen";
import OurCapabilitiesScreen from "../screen/OurCapabilitiesScreen";
import ContactUsScreen from "../screen/ContactUsScreen";
// import LatestNewsScreen from "../screen/LatestNewsScreen";
import JoinUsScreen from "../screen/JoinUsScreen";
import WhoWeAreScreen from "../screen/WhoWeAreScreen";
import ResourcesScreen from "../screen/ResourcesScreen";
import CareersComponent from "../screen/CareerScreen";



export const routes =[
    {
        path: "/",
        name: "LandingScreen",
        element: <LandingScreen/>,
        isPrivate: false
    },
    
    {
        path: "/what-we-do",
        name: "WhatWeDoScreen",
        element: <WhatWeDoScreen />,
        isPrivate: false
    },

    {
        path: "/who-we-are",
        name: "WhoWeAreScreen",
        element: <WhoWeAreScreen />,
        isPrivate: false
    },
   
    {
        path: "/join-our-team",
        name: "JoinOurTeamScreen",
        element: <JoinUsScreen />,
        isPrivate: false
    },
    
    {
        path: "/contact-us",
        name: "ContactUsScreen",
        element: <ContactUsScreen />,
        isPrivate: false
    },
   
    {
        path: "/helpful-links",
        name: "HelpfulLinksScreen",
        // element:
        isPrivate: false
    }, 
   
    {
        path: "/news",
        name: "NewsScreen",
        element: <NewsScreen />,
        isPrivate: false
    }, 

    {
        path: "/resources",
        name: "ResourcesScreen",
        element: <ResourcesScreen />,
        isPrivate: false
    }, 
    
    {
        path: "/meet-our-people/career/life-at",
        name: "PeopleStoriesComponent",
        element: <PeopleStoriesComponent />,
        isPrivate: false
    }, 
   
    {
        path: "/our-capabilities",
        name: "OurCapabilitiesScreen",
        element: <OurCapabilitiesScreen />,
        isPrivate: false
    }, 
    
    {
        path: "/career",
        name: "CareersComponent",
        element: <CareersComponent />,
        isPrivate: false
    }, 
    
]
