import React from "react";
import useWidth from "../hooks/useWidth";
import AboutUsComponent from "../components/who-we-are/who-we-are.components";

const WhoWeAreScreen = () => {

  const breakpoints = useWidth();

  return (
    <div>
      <AboutUsComponent breakpoints={breakpoints} />
    </div>
  )
}

export default WhoWeAreScreen
