import { Box, Button } from "@mui/material"
import LandingContentComponent from "../components/landing-page/landing-content.component"

const LandingScreen = () => {

    return (

        <Box sx={{ mt: 2, mb: 2 }}>
            
            <LandingContentComponent />

        </Box>

    )
}

export default LandingScreen
