import {Grid, Box, Typography, TextField, Button, Checkbox, FormControlLabel} from "@mui/material";
import NextixLogo from "../../assets/icons/nextix-logo.png";
import ReactPlayer from "react-player";
import EastIcon from "@mui/icons-material/East";
import InstagramIcon from "../../assets/icons/social-icons/instagram.png";
import TwitterIcon from "../../assets/icons/social-icons/twitter.png";
import FacebookIcon from "../../assets/icons/social-icons/facebook -outlined.png";
import YoutubeIcon from "../../assets/icons/social-icons/youtube.png";

const JoinUsComponent = (props) => {

    const {breakpoints} = props;

    return (
            <Box sx={{backgroundColor: "#F3F3F3", width: "95%", margin: "auto"}}>
                <Grid container sx={{backgroundColor: "#F3F3F3", paddingTop: "35px", textAlign: "start"}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <img alt="" src={NextixLogo} onClick={(e) => window.location.href = "/"}
                            style={{width: (breakpoints == "xs" || breakpoints == "sm") ? 80 : 120, verticalAlign: "middle", cursor: "pointer"}}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography sx={{fontSize: (breakpoints == "xs") ? "18px" : (breakpoints == "sm") ? "20px" : "36px", color: "#070031", fontWeight: "bold"}}>
                            Nextix Talent Community
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box sx={{backgroundColor: "#070031", height: "20px", mb: 4, width: (breakpoints == "xs" || breakpoints == "sm") ? "100%" : "100%"}}>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ReactPlayer
                            url={"https://www.youtube.com/watch?v=38k5zr1e0HI"}
                            playing={true}
                            controls={true}
                            width="100%"
                            height={(breakpoints == "xs" || breakpoints == "sm") ? "300px" : "580px"}
                            muted={true}
                            style={{objectFit: "contain", textAlign: "start", height: "100%"}}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{textAlign: "start", pl: "0px !important"}}>
                        <Typography variant="h6" sx={{color: "#070031", fontSize: "22px", fontWeight: "bold", pt: "16px", pb: "20px"}}>
                            Are you ready to take on the challenge?
                        </Typography>

                        <Typography variant="h6" sx={{color: "#070031", fontSize: "16px", pb: "15px", mt: -1}}>
                            Didn’t find the perfect job for you today? Don’t go anywhere before signing up to our talent community! We will notify you
                            of any new jobs that may be of interest to you. You can also upload your CV so our recruiters can get in touch with you. 
                        </Typography>
                    </Grid>

                    <Box sx={{border: "1px solid #070031", textAlign: "center", mt: 1, padding: (breakpoints == "xs" || breakpoints == "sm") ? "25px" : "90px", width: "100%"}}>
                        <Grid container direction="column">
                            <Typography sx={{fontSize: "16px", mb: 2, color: "#070031"}}>
                                Upload your resume and get noticed by our recruiters
                            </Typography>

                            <Box>
                                <Button variant="contained" endIcon={<EastIcon sx={{color: "#fff"}} />} 
                                    sx={{borderRadius: "18px", backgroundColor: "#99CC00", fontSize: "16px", textTransform: "none", color: "#fff",
                                        "&:hover": {backgroundColor: "#99CC00"}
                                    }}
                                >
                                    Upload Resume
                                </Button>
                            </Box>
                        </Grid>
                    </Box>

                    <Grid container spacing={3} sx={{mt: 1}}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid xs={12}>
                                <Typography sx={{fontSize: "16px", color: "#070031"}}>
                                    First Name<span style={{color: "red"}}>*</span>
                                </Typography>

                                <TextField 
                                    variant="outlined" 
                                    fullWidth 
                                    InputProps={{style: {}}}
                                    sx={{
                                        "& .MuiOutlinedInput-root": { 
                                            "& fieldset": {           
                                                borderColor: "#070031",  
                                                borderRadius: "0px", 
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#070031", 
                                            },
                                            "&.Mui-focused fieldset": { 
                                                borderColor: "#070031",
                                            },
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid> 

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid>
                                <Typography sx={{fontSize: "16px", color: "#070031"}}>
                                    Last Name<span style={{color: "red"}}>*</span>
                                </Typography>

                                <TextField 
                                    variant="outlined"
                                    fullWidth 
                                    sx={{
                                        "& .MuiOutlinedInput-root": { 
                                            "& fieldset": {           
                                                borderColor: "#070031",  
                                                borderRadius: "0px", 
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#070031", 
                                            },
                                            "&.Mui-focused fieldset": { 
                                                borderColor: "#070031",
                                            },
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} sx={{mt: 1}}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid xs={12}>
                                <Typography sx={{fontSize: "16px", color: "#070031"}}>
                                    Email Address<span style={{color: "red"}}>*</span>
                                </Typography>

                                <TextField 
                                    variant="outlined" 
                                    fullWidth 
                                    sx={{
                                        "& .MuiOutlinedInput-root": { 
                                            "& fieldset": {           
                                                borderColor: "#070031", 
                                                borderRadius: "0px",  
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#070031", 
                                            },
                                            "&.Mui-focused fieldset": { 
                                                borderColor: "#070031",
                                            },
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid> 

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid>
                                <Typography sx={{fontSize: "16px", color: "#070031"}}>
                                    Phone Number<span style={{color: "red"}}>*</span>
                                </Typography>

                                <TextField 
                                    variant="outlined"
                                    fullWidth  
                                    sx={{
                                        "& .MuiOutlinedInput-root": { 
                                            "& fieldset": {           
                                                borderColor: "#070031",  
                                                borderRadius: "0px", 
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#070031", 
                                            },
                                            "&.Mui-focused fieldset": { 
                                                borderColor: "#070031",
                                            },
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid>
                                <Typography sx={{fontSize: "16px", color: "#070031"}}>
                                    Position Applied For
                                </Typography>

                                <TextField 
                                    variant="outlined"
                                    fullWidth  
                                    sx={{
                                        "& .MuiOutlinedInput-root": { 
                                            "& fieldset": {           
                                                borderColor: "#070031",  
                                                borderRadius: "0px", 
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#070031", 
                                            },
                                            "&.Mui-focused fieldset": { 
                                                borderColor: "#070031",
                                            },
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid>
                                <Typography sx={{fontSize: "16px", color: "#070031"}}>
                                    LinkedIn Profile
                                </Typography>

                                <TextField 
                                    variant="outlined"
                                    fullWidth  
                                    sx={{
                                        "& .MuiOutlinedInput-root": { 
                                            "& fieldset": {           
                                                borderColor: "#070031",  
                                                borderRadius: "0px", 
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#070031", 
                                            },
                                            "&.Mui-focused fieldset": { 
                                                borderColor: "#070031",
                                            },
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid>
                                <Typography sx={{fontSize: "16px", color: "#070031"}}>
                                    Top 3-5 skillsets you possess that translate to your applied role<span style={{color: "red"}}>*</span>
                                </Typography>

                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    multiline="true"
                                    minRows={8}
                                    sx={{
                                        "& .MuiOutlinedInput-root": { 
                                            "& fieldset": {           
                                                borderColor: "#070031",  
                                                borderRadius: "0px", 
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#070031", 
                                            },
                                            "&.Mui-focused fieldset": { 
                                                borderColor: "#070031",
                                            },
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box>
                        <FormControlLabel 
                            control={<Checkbox defaultChecked />} 
                            label={
                                <Typography sx={{fontSize: "16px", color: "#070031"}}>
                                    “I consent to Nextix using my information for legitimate business purposes.”
                                </Typography>
                            }
                        />
                    </Box>

                    <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{textAlign: "end", mb: '20px'}}>
                        <Button variant="contained" endIcon={<EastIcon sx={{color: "#fff"}} />} 
                            sx={{borderRadius: "18px", backgroundColor: "#99CC00", fontSize: "16px", textTransform: "none", color: "#fff",
                                mt: (breakpoints == "xs" || breakpoints == "sm") ? 4 : {},
                                "&:hover": {backgroundColor: "#99CC00"}
                            }}
                        >
                            Submit
                        </Button>
                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt: "36px"}}>
                        <Box sx={{backgroundColor: "#070031", display: "block", alignItems: "center", height: 150, pt: "50px", textAlign: "center"}}>
                            <Box sx={{display: "flex", justifyContent: "center"}}>
                                <img alt="" src={InstagramIcon} style={{width: (breakpoints == "xs" || breakpoints == "sm") ? 20 : 22, height: (breakpoints == "xs" || breakpoints == "sm") ? 20 : 22, marginRight: "10px"}} />

                                <img alt="" src={TwitterIcon} style={{width: (breakpoints == "xs" || breakpoints == "sm") ? 20 : 22, height: (breakpoints == "xs" || breakpoints == "sm") ? 20 : 22, marginRight: "10px"}} />

                                <img alt="" src={FacebookIcon} style={{width: (breakpoints == "xs" || breakpoints == "sm") ? 21 : 23, height: (breakpoints == "xs" || breakpoints == "sm") ? 21 : 23, marginRight: "5px"}} />

                                <img alt="" src={YoutubeIcon} style={{width: (breakpoints == "xs" || breakpoints == "sm") ? 28 : 30, height: (breakpoints == "xs" || breakpoints == "sm") ? 28 : 30, marginRight: "10px"}} />
                            </Box>

                            <Typography sx={{color: "#fff", mt: "4px", fontSize: "16px"}}>
                                © next|ix, 2024. All rights reserved.
                            </Typography>
                        </Box>
                    </Grid> */}
                </Grid>
            </Box>
    )
}

export default JoinUsComponent 