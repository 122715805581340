import DrawerHeader from '../layout/drawer-header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram, faTiktok, faYoutube, faPinterest, faTelegram, faRedditSquare, faWeixin, faMedium, faDigg, faMix } from '@fortawesome/free-brands-svg-icons';
import { Link } from '@mui/material';

function FooterSocialIconsComponent() {

    return(
        <div>
            <div class="soc-widget">
                <Link target="_blank" href="https://www.facebook.com/OVCodeSwitzerlandAG/" underline="none">
                    <i><FontAwesomeIcon icon={faFacebook}  /></i>
                </Link>
              
                <Link target="_blank" href="https://twitter.com/OVCode">
                    <i><FontAwesomeIcon icon={faTwitter} /></i>
                </Link>

                <Link target="_blank" href="https://www.instagram.com/ovcodeswitzerlandag/">
                    <i><FontAwesomeIcon icon={faInstagram} /></i>
                </Link>
             
                <Link href="https://www.youtube.com/channel/UC4658E4JwgY3IFdqqohZBWw">
                    <i><FontAwesomeIcon icon={faYoutube} /></i>
                </Link>
                <Link href="https://www.youtube.com/channel/UC4658E4JwgY3IFdqqohZBWw">
                    <i><FontAwesomeIcon icon={faTiktok} /></i>
                </Link>
            </div>
            
        </div>
    )
    
}

export default FooterSocialIconsComponent