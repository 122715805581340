const LatestNewsComponent = () => {

    return (

        <div class="row" style={{ textAlign: 'start' }}>
            <div class="col-12 pl-0 pr-0">
                <div class="relatedItemContainer">
                    <div class="relatedItemTile">
                        <div class="relatedItemImage">
                            <a target="_self" href="https://www.portcalls.com/nextix-shiptek-lone-bidder-ppa-container-monitoring/">
                                <picture>
                                    <img src="https://www.portcalls.com/wp-content/uploads/2022/03/container-6559906_640-1.jpg.webp" alt="The Invisible Force Powering Our World" class="img-fluid" />
                                </picture>
                            </a>
                        </div>
                        <div class="">
                            <div class="relatedItemDate">Feb 13, 2024</div>
                            <div class="relatedItemType">Story</div>
                            <div class="relatedItemTitle"><a target="_self" href="https://www.portcalls.com/nextix-shiptek-lone-bidder-ppa-container-monitoring/" style={{color: '#000'}} >NextIX and Shiptek joint venture is lone bidder for PPA container monitoring project</a></div>
                            <div class="relatedItemCTA"><a target="_self" href="https://www.portcalls.com/nextix-shiptek-lone-bidder-ppa-container-monitoring/" style={{textDecoration: 'none'}} >Read More</a></div>
                        </div>
                    </div>

                    <div class="relatedItemTile">
                        <div class="relatedItemImage">
                            <a target="_self" href="https://www.philstar.com/headlines/2017/08/28/1733792/lto-start-distribution-5-year-drivers-license-cards">
                                <picture>
                                    <img src="https://media.philstar.com/images/the-philippine-star/headlines/20151027/Drivers-license-7.jpg" alt="The Invisible Force Powering Our World" class="img-fluid" />
                                </picture>
                            </a>
                        </div>
                        <div class="">
                            <div class="relatedItemDate">Feb 13, 2024</div>
                            <div class="relatedItemType">Story</div>
                            <div class="relatedItemTitle"><a target="_self" href="https://www.philstar.com/headlines/2017/08/28/1733792/lto-start-distribution-5-year-drivers-license-cards" style={{color: '#000'}} >LTO to start distribution of 5-year driver’s license cards</a></div>
                            <div class="relatedItemCTA"><a target="_self" href="https://www.philstar.com/headlines/2017/08/28/1733792/lto-start-distribution-5-year-drivers-license-cards" style={{textDecoration: 'none'}} >Read More</a></div>
                        </div>
                    </div>

                    <div class="relatedItemTile">
                        <div class="relatedItemImage">
                            <a target="_self" href="https://medium.com/@nextix/ps-philgeps-launches-virtual-store-a6c8d60820e4">
                                <picture>
                                    <img src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*UeILNZdzdfpJM1lsCkYrdw.png" alt="The Invisible Force Powering Our World" class="img-fluid" />
                                </picture>
                            </a>
                        </div>
                        <div class="">
                            <div class="relatedItemDate">Feb 13, 2024</div>
                            <div class="relatedItemType">Story</div>
                            <div class="relatedItemTitle"><a target="_self" href="https://medium.com/@nextix/ps-philgeps-launches-virtual-store-a6c8d60820e4" style={{color: '#000'}} >PS-PHILGEPS Launches Virtual Store</a></div>
                            <div class="relatedItemCTA"><a target="_self" href="https://medium.com/@nextix/ps-philgeps-launches-virtual-store-a6c8d60820e4" style={{textDecoration: 'none'}} >Read More</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default LatestNewsComponent