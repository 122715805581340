import React, { useState, useEffect, useRef } from 'react';
import { Card, CardMedia, Typography, Grid, Box } from '@mui/material';
import '../../CompaniesWorkWithSwiper.css';

import MicrosoftPartner from '../../assets/icons/companies-worked-with/microsoft-partner.png';
import HuaweiPartner from '../../assets/icons/companies-worked-with/parner-huawei.png';
import PldtPartner from '../../assets/icons/companies-worked-with/p-pldt-logo.png';
import AwsPartner from '../../assets/icons/companies-worked-with/partners-aws.png';
import OvcodePartner from '../../assets/icons/companies-worked-with/partners-ovcode.png';
import ThalesPartner from '../../assets/icons/companies-worked-with/partners-thales.png';

const CompaniesWorkWith = () => {
    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (isInView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    const companyData = [
        { image: MicrosoftPartner, name: 'Microsoft' },
        { image: HuaweiPartner, name: 'Huawei' },
        { image: PldtPartner, name: 'PLDT' },
        { image: AwsPartner, name: 'AWS' },
        { image: OvcodePartner, name: 'OVCODE' },
        { image: ThalesPartner, name: 'Thales' }
    ];

    return (
        <Box sx={{ minHeight: '40vh' }}>
            <div ref={sectionRef} class="container-fluid container-xxxl">
                <Grid container sx={{ textAlign: 'start', position: 'relative' }}>
                    <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16, marginTop: '30px' }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}>
                            Our Partners &nbsp; ___
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            <Box sx={{ textAlign: 'left', color: '#070031', my: 4, pr: 6, pb: 5, width: '91%', margin: 'auto' }}>
                <Grid container spacing={2} justifyContent="center">
                    {companyData.map((company, i) => (
                        <Grid item xs={12} sm={6} md={4} key={i}>
                            <Card sx={{  width: '90%', margin: 'auto', boxShadow: 'none'}}>
                                <CardMedia
                                    component="img"
                                    height="150"
                                    image={company.image}
                                    alt={company.name}
                                    sx={{ width: 'auto', margin: 'auto', objectFit: 'cover' }}
                                />
                                {/* <Typography variant="body1" sx={{ color: "#070031", padding: "12px", fontSize: "20px" }}>
                                    {company.name}
                                </Typography> */}
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default CompaniesWorkWith;