import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import PSA from './../../assets/icons/technology-partners/psa-logo.jpg';
import DTI from './../../assets/icons/technology-partners/dti-logo.jpg';
import DCI from './../../assets/icons/technology-partners/dci-logo.jpg';
import PHIVOLCS from './../../assets/icons/technology-partners/phivolcs-logo.jpg';
import DSWD from './../../assets/icons/technology-partners/dswd-logo.jpg';
import COE from './../../assets/icons/technology-partners/coe-logo.jpg';
import DBM from './../../assets/icons/technology-partners/dbm-logo.jpg';
import COPYLANDIA from './../../assets/icons/technology-partners/copylandia-logo.jpg';  
import DIMSUM from './../../assets/icons/technology-partners/dimsum.jpg';  
import JOLLIBEE from './../../assets/icons/technology-partners/jollibee-logo.jpg';  
import PSE from './../../assets/icons/technology-partners/pse-logo.jpg';  
import UNILAB from './../../assets/icons/technology-partners/unilab-logo.jpg';  
import PHILGEPS from './../../assets/icons/technology-partners/philgeps-logo.jpg';  
import POSC from './../../assets/icons/technology-partners/posc-logo.jpg';  
import PNB from './../../assets/icons/technology-partners/pnb-logo.jpg';  
import PNP from './../../assets/icons/technology-partners/pnp-logo.jpg';  
import BSM from './../../assets/icons/technology-partners/bsm-logo.jpg';  
import KFC from './../../assets/icons/technology-partners/kfc-logo.jpg'; 
import LUFTHANSA from './../../assets/icons/technology-partners/lufthansatechnik-logo.jpg'; 
import METRODENTAL from './../../assets/icons/technology-partners/metrodental-logo.jpg'; 
import MONACO from './../../assets/icons/technology-partners/monaco1-logo.jpg'; 
import MAXICARE from './../../assets/icons/technology-partners/maxicare-logo.jpg'
import MAGSAYSAY from './../../assets/icons/technology-partners/magsaysay-logo.jpg'
import MYHEALTH from './../../assets/icons/technology-partners/myhealthclinic-logo.jpg'
import TAO from './../../assets/icons/technology-partners/tao-logo.jpg'
import SUN from './../../assets/icons/technology-partners/suncellular-logo.jpg'
import SPI from './../../assets/icons/technology-partners/spiglobal-logo.jpg'
import TIPCO from './../../assets/icons/technology-partners/tipco-logo.jpg'
import STERLING from './../../assets/icons/technology-partners/sterling-logo.png'
import TORRE from './../../assets/icons/technology-partners/toreelorenzo-logo.jpg'
import STMARTIN from './../../assets/icons/technology-partners/stmartinoftours-logo.jpg'
import TALK from './../../assets/icons/technology-partners/51-talk-logo.jpg'
import TOYOTA from './../../assets/icons/technology-partners/toyota-logo.jpg'
import WATERFRONT from './../../assets/icons/technology-partners/waterfront-logo.jpg'
import USSC from './../../assets/icons/technology-partners/ussc-logo.jpg'
import VITRO from './../../assets/icons/technology-partners/vitro-logo.jpg'
import ETON from './../../assets/icons/technology-partners/eton-logo.jpg'
import FORTUNE from './../../assets/icons/technology-partners/fortune.jpg'
import INTELLICARE from './../../assets/icons/technology-partners/intellicare.jpg'
import ABOITIZ from './../../assets/icons/technology-partners/aboitizland-logo.jpg'
import PUREFORCE from './../../assets/icons/technology-partners/pureforce-logo.jpg'
import ALLIED from './../../assets/icons/technology-partners/alliedbank-logo.jpg'
import ALPHADENT from './../../assets/icons/technology-partners/alphadent-logo.jpg'
import ASERCO from './../../assets/icons/technology-partners/aserco-logo.jpg'
import DOUBLE from './../../assets/icons/technology-partners/double-dragon-logo.jpg'
import WALTERMART from './../../assets/icons/technology-partners/waltermart.jpg'
import FAITH from './../../assets/icons/technology-partners/faith.jpg'
import PHLPOST from './../../assets/icons/technology-partners/phlpost-logo.jpg'
import OCEANIC from './../../assets/icons/technology-partners/oceanic-logo.jpg'
import NETSUITE from './../../assets/icons/technology-partners/netsuite-logo.jpg'
import EK from './../../assets/icons/technology-partners/ek-logo.jpg'
import BELO from './../../assets/icons/technology-partners/belo-logo.jpg' 
import ELECTRO from './../../assets/icons/technology-partners/electroworld-logo.jpg'
import IOT from './../../assets/icons/technology-partners/iot-logo.jpg'
import DSWD2 from './../../assets/icons/technology-partners/client-dswd-1.jpg'

const TechnologyPartners = () => {
    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (isInView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    const partnersData = [
        { image: PSA, name: 'PSA' },
        { image: DTI, name: 'DTI' },
        { image: DCI, name: 'DCI' },
        { image: PHIVOLCS, name: 'PHIVOLCS' },
        { image: DSWD, name: 'DSWD' },
        { image: COE, name: 'COE' },
        { image: DBM, name: 'DBM' },
        { image: COPYLANDIA, name: 'Copylandia'},
        { image: DIMSUM, name: 'Dimsum Break' },
        { image: JOLLIBEE, name: 'Jollibee' },
        { image: PSE, name: 'PSE' },
        { image: PHILGEPS, name: 'PHILGEPS' },
        { image: POSC, name: 'POSC' },
        { image: PNB, name: 'PNB' },
        { image: PNP, name: 'PNP' },
        { image: BSM, name: 'BSM' },
        { image: KFC, name: 'KFC' },
        { image: LUFTHANSA, name: 'Lufthansa Technik' },
        { image: METRODENTAL, name: 'MetroDental' },
        { image: MONACO, name: 'Monaco1 International Inc.' },
        { image: MAXICARE, name: 'Maxicare' },
        { image: MAGSAYSAY, name: 'Magsaysay' },
        { image: MYHEALTH, name: 'MyHealth Clinic' },
        { image: PHLPOST, name: 'PHLPOST' },
        { image: OCEANIC, name: 'Oceanic Container Lines' },
        { image: NETSUITE, name: 'NetSuite' },
        { image: IOT, name: 'IOT' },
        { image: EK, name: 'Enchanted Kingdom' },
        { image: BELO, name: 'Belo Medical Group' },
        { image: ELECTRO, name: 'Electroworld' },
        { image: TAO, name: 'Tao Corporation' },
        { image: STERLING, name: 'Sterling Global' },
        { image: TORRE, name: 'Torre Lorenzo' },
        { image: STMARTIN, name: 'ST Martin of Tours' },
        { image: TALK, name: '51Talk' },
        { image: SUN, name: 'Sun Cellular' },
        { image: SPI, name: 'SPi Global' },
        { image: TIPCO, name: 'TIPCO' },
        { image: TOYOTA, name: 'Toyota' },
        { image: WATERFRONT, name: 'Waterfront Hotel and Casino' },
        { image: USSC, name: 'USSC' },
        { image: VITRO, name: 'Vitro' },
        { image: ETON, name: 'Eton Properties ' },
        { image: FORTUNE, name: 'FortuneCare' },
        { image: INTELLICARE, name: 'IntelliCare' },
        { image: ABOITIZ, name: 'Aboitiz' },
        { image: PUREFORCE, name: 'Pureforce' },
        { image: ALLIED, name: 'Allied Bank' },
        { image: ALPHADENT, name: 'AlphaDent' },
        { image: ASERCO, name: 'Aserco' },
        { image: DOUBLE, name: 'DoubleDragon' },
        { image: FAITH, name: 'Faith Academy' },
        { image: WALTERMART, name: 'WalterMart' },
        // { image: UNILAB, name: 'Unilab' },
        { image: DSWD2, name: 'DSWD' },

    ];

    return (
        <Box>
            <div ref={sectionRef} className="container-fluid container-xxxl">
            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >Our Clients&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Grid container sx={{textAlign: 'start', position: 'relative', padding: 4, paddingTop: 3, paddingBottom: 3}} >
                <Typography sx={{fontSize: { xs: 28, sm: 34, md: 20 }}}>We identify and execute growth initiatives with our core partners for specific market segments.</Typography>
            </Grid>
            
            <Box sx={{ textAlign: 'left', color: '#070031', my: 4, pr: 6, pb: 5, width: '91%', margin: 'auto' }}>
              <Grid container spacing={2} justifyContent="center">
                  {partnersData.map((partner, index) => (
                      <Grid item xs={6} sm={4} md={2} key={index}>
                          <Card sx={{ width: '100%', margin: '10px' }}>
                              <Box sx={{ margin: '10px', textAlign: 'center' }}>
                                  <CardMedia
                                      component="img"
                                      height="100" // Reduced height from 150 to 100
                                      image={partner.image}
                                      alt={partner.name}
                                      sx={{ width: 'auto', margin: 'auto', objectFit: 'cover' }} // Added width styling
                                  />
                              </Box>
                              {/* <Typography variant="body1" sx={{ color: "#070031", padding: "12px", fontSize: "20px", textAlign: "center" }}>
                                  {partner.name}
                              </Typography> */}
                          </Card>
                      </Grid>
                  ))}
              </Grid>
          </Box>
          </div>
        </Box>
    );
};

export default TechnologyPartners;
